module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions Légales"])},
        "mentions-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse du siège social"])},
        "mentions-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations Financières"])},
        "mentions-2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forme Juridique"])},
        "mentions-2-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société par actions simplifiée"])},
        "mentions-2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de TVA intracommunautaire"])},
        "mentions-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergeur & Responsable"])},
        "mentions-3-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable de la publication"])},
        "mentions-3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergeur du site"])},
        "mentions-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse Établissement"])},
        "mentions-4-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseigne"])},
        "mentions-4-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établissement"])},
        "mentions-4-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital social"])},
        "mentions-4-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse mail"])},
        "mentions-4-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tel standard Corlet Logistic"])},
        "mentions-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions générales de vente"])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Notices"])},
        "mentions-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Headquarters Address"])},
        "mentions-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Information"])},
        "mentions-2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Form"])},
        "mentions-2-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplified joint stock company"])},
        "mentions-2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intracommunity VAT Number"])},
        "mentions-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host & Responsible"])},
        "mentions-3-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication Manager"])},
        "mentions-3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website Host"])},
        "mentions-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establishment Address"])},
        "mentions-4-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Name"])},
        "mentions-4-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establishment"])},
        "mentions-4-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share Capital"])},
        "mentions-4-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
        "mentions-4-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corlet Logistic Phone Number"])},
        "mentions-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions of Sale"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"title":"Mentions Légales","mentions-1":"Adresse du siège social","mentions-2":"Informations Financières","mentions-2-1":"Forme Juridique","mentions-2-1-1":"Société par actions simplifiée","mentions-2-2":"Numéro de TVA intracommunautaire","mentions-3":"Hébergeur & Responsable","mentions-3-1":"Responsable de la publication","mentions-3-2":"Hébergeur du site","mentions-4":"Adresse Établissement","mentions-4-1":"Enseigne","mentions-4-2":"Établissement","mentions-4-3":"Capital social","mentions-4-4":"Adresse mail","mentions-4-5":"Tel standard Corlet Logistic","mentions-5":"Conditions générales de vente"},"en":{"title":"Legal Notices","mentions-1":"Headquarters Address","mentions-2":"Financial Information","mentions-2-1":"Legal Form","mentions-2-1-1":"Simplified joint stock company","mentions-2-2":"Intracommunity VAT Number","mentions-3":"Host & Responsible","mentions-3-1":"Publication Manager","mentions-3-2":"Website Host","mentions-4":"Establishment Address","mentions-4-1":"Business Name","mentions-4-2":"Establishment","mentions-4-3":"Share Capital","mentions-4-4":"Email Address","mentions-4-5":"Corlet Logistic Phone Number","mentions-5":"Terms and Conditions of Sale"}}')
  delete Component.options._Ctor
  
}
