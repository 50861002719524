module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse créée"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse modifiée"])},
        "create_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une adresse"])},
        "edit_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'adresse"])}
      },
      "en": {
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address created"])},
        "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address updated"])},
        "create_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create address"])},
        "edit_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit address"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"created":"Adresse créée","updated":"Adresse modifiée","create_address":"Créer une adresse","edit_address":"Modifier l\u0027adresse"},"en":{"created":"Address created","updated":"Address updated","create_address":"Create address","edit_address":"Edit address"}}')
  delete Component.options._Ctor
  
}
