import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home';
import Dashboard from '@/views/Dashboard';
import Library from '@/views/Library';
import Orders from '@/views/Orders';
import Account from '@/views/Account';
import Simulation from '@/views/Simulation';
// import SimulationTest from '@/views/SimulationTest';
import Admin from '@/views/Admin';
import Login from '@/views/Login';
import Register from '@/views/Register';
import Address from '@/views/Address';
import Contact from '@/views/Contact';
import Mentions from '@/views/Mentions';
import ResetPassword from '@/views/ResetPassword';
import Help from '@/views/Help';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
	{
		path: '',
		name: 'Home',
		component: Home,
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: Dashboard,
		beforeEnter(to, from, next) {
			userRoute(to, from, next);
		},
	},
	{
		path: '/contact',
		name: 'Contact',
		component: Contact,
	},
	{
		path: '/mentions',
		name: 'Mentions',
		component: Mentions,
	},
	{
		path: '/reset_password',
		name: 'ResetPassword',
		component: ResetPassword,
	},
	{
		path: '/bibliotheque',
		name: 'Library',
		component: Library,
		beforeEnter(to, from, next) {
			userRoute(to, from, next);
		},
	},
	{
		path: '/commandes',
		name: 'Orders',
		component: Orders,
		beforeEnter(to, from, next) {
			if (userRoute(to, from, next)) {
				parentRoute(to, from, next);
			}
		},
	},
	{
		path: '/adresse',
		name: 'Address',
		component: Address,
		beforeEnter(to, from, next) {
			if (userRoute(to, from, next)) {
				parentRoute(to, from, next);
			}
		},
	},
	{
		path: '/account',
		name: 'Account',
		component: Account,
		beforeEnter(to, from, next) {
			userRoute(to, from, next);
		},
	},
	{
		path: '/simulation',
		name: 'Simulation',
		component: Simulation,
		beforeEnter(to, from, next) {
			if (userRoute(to, from, next)) {
				parentRoute(to, from, next);
			}
		},
	},
	// {
	// 	path: '/simulation-test',
	// 	name: 'SimulationTest',
	// 	component: SimulationTest,
	// 	beforeEnter(to, from, next) {
	// 		adminRoute(to, from, next);
	// 	},
	// },
	{
		path: '/admin',
		name: 'Admin',
		component: Admin,
		beforeEnter(to, from, next) {
			adminRoute(to, from, next);
		},
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
	},
	{
		path: '/register',
		name: 'Register',
		component: Register,
		beforeEnter(to, from, next) {
			adminRoute(to, from, next);
		},
	},
	{
		path: '/logout',
		name: 'Logout',
		beforeEnter(to, from, next) {
			store.dispatch('logout').then(() => next('/'));
		},
	},
	{
		path: '/help',
		name: 'Help',
		component: Help,
		beforeEnter(to, from, next) {
			userRoute(to, from, next);
		},
	},
];

function adminRoute(to, from, next) {
	if (!store.getters.isAuthenticated) {
		next('/login');
	} else {
		if (!store.getters.isAdmin) {
			next('/');
		} else {
			next();
		}
	}
}

function userRoute(to, from, next) {
	if (!store.getters.isAuthenticated) {
		next('/login');
		return false;
	} else {
		next();
		return true;
	}
}

function parentRoute(to, from, next) {
	if (!store.state.user) {
		setTimeout(() => {
			if (!store.getters.isAuthenticated || store.getters.isChild) {
				next('/');
			} else {
				next();
			}
		}, 2000);
	} else {
		if (!store.getters.isAuthenticated || store.getters.isChild) {
			next('/');
		} else {
			next();
		}
	}
}

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
});

export default router;
