module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes Adresses"])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Addresses"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"title":"Mes Adresses"},"en":{"title":"My Addresses"}}')
  delete Component.options._Ctor
  
}
