<template>
	<div>
		<div class="mb-3 d-flex align-center">
			<v-icon class="orange--text">mdi-pin</v-icon>:
			{{ $t('address.billing') }}
		</div>
		<div class="mb-3 d-flex align-center">
			<v-icon class="orange--text">mdi-star</v-icon>:
			{{ $t('address.favorite') }}
		</div>
		<div v-if="loading" class="text-center">
			<v-progress-circular :size="150" color="yellow" indeterminate class="my-10">
				{{ $t('loading') }}
			</v-progress-circular>
		</div>
		<v-data-iterator
			v-else
			:items="addresses"
			hide-default-footer
			:items-per-page="-1"
			:sort-by="sortBy"
			:sort-desc="sortDesc"
			:search="search"
		>
			<template #header>
				<div class="d-md-flex">
					<v-select
						v-model="sortBy"
						:items="[
							{ text: $t('address.favorite'), value: 'favorite' },
							{ text: $t('address.billing'), value: 'pinned' },
							{ text: $t('address.address'), value: 'address' },
							{ text: $t('address.city'), value: 'city' },
							{ text: $t('address.postal_code'), value: 'code_postal' },
							{ text: $t('address.country'), value: 'country.iso' },
							{ text: $t('global.phone'), value: 'phone' },
							{ text: $t('global.email'), value: 'email' },
							{ text: $t('global.created_at'), value: 'created_at' },
						]"
						:label="$t('sort')"
						outlined
						class="border-shaped mr-2"
					></v-select>
					<v-btn-toggle v-model="sortDesc" mandatory class="mt-n4 mt-md-0">
						<v-btn x-large class="border-shaped" :value="false">
							<v-icon>mdi-arrow-up</v-icon>
						</v-btn>
						<v-btn x-large class="border-shaped mx-2" :value="true">
							<v-icon>mdi-arrow-down</v-icon>
						</v-btn>
					</v-btn-toggle>
					<v-text-field
						v-model="search"
						outlined
						:label="$t('form.search')"
						prepend-inner-icon="mdi-magnify"
						class="border-shaped mt-3 mt-md-0"
					></v-text-field>
				</div>
			</template>
			<template #default="props">
				<v-row>
					<v-col v-for="item in props.items" :key="item.id" cols="12" sm="6" md="6" lg="4" xl="3">
						<v-lazy transition="scale-transition">
							<AddressDetail :address="item" />
						</v-lazy>
					</v-col>
				</v-row>
			</template>
			<template #no-data>
				<i class="ml-1">{{ $t('no_data') }}</i>
			</template>
		</v-data-iterator>
	</div>
</template>

<script lang="js">
	import axios from 'axios';
	import { EventBus } from '@/utils';
	import AddressForm from '@/components/address/AddressForm';
	import AddressDetail from '@/components/address/AddressDetail';

	export default {
		name: 'AddressList',
		components: {
			AddressForm,
			AddressDetail,
		},
		props: [],
		data() {
			return {
				loading: false,
				addresses: [],
				sortBy: 'pinned',
				sortDesc: true,
				search: '',
			};
		},
		computed: {},
		mounted() {
			this.getAddresses();
			EventBus.$on('refreshAddresses', () => {
				this.getAddresses(false);
			});
		},
		methods: {
			getAddresses(handleloading = true) {
				this.loading = handleloading;
				axios
					.get(process.env.VUE_APP_API_URL + '/address', {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then((response) => {
						this.addresses = response.data.addresses;
						this.loading = false;
					});
			},

		},
	};
</script>

<i18n lang="json">
{
	"fr": {
		"sort": "Trier par",
		"date": "Date",
		"items_per_page": "Produits par page",
		"no_data": "Aucune adresse enregistrée"
	},
	"en": {
		"sort": "Sort by",
		"date": "Date",
		"items_per_page": "Products per page",
		"no_data": "No address registered"
	}
}
</i18n>
