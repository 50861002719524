<template>
	<div>
		<PageTitle :title="$t('title')" :breadcrumbs="items"></PageTitle>
		<v-dialog v-model="showHistory" scrollable :overlay="false" max-width="1300" transition="dialog-transition">
			<v-overlay v-model="loadingPDF">
				<v-progress-circular indeterminate size="64"></v-progress-circular>
			</v-overlay>
			<v-sheet class="border-shaped" elevation="24">
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title class="text-h5 text-lg-h4"> {{ $t('history') }} </v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-divider class="mb-4"></v-divider>
				<v-data-table
					:headers="historyHeaders"
					:items="history"
					:loading="loadingHistory"
					show-expand
					single-expand
					:expanded.sync="historyExpanded"
					:footerProps="footerProps"
					sort-by="datetime"
					sort-desc
				>
					<template #no-data>
						{{ $t('no_history') }}
					</template>
					<template #item.data.prix_livraison="{ item }"> {{ parseFloat(item.data.prix_livraison).toFixed(2) }} € </template>
					<template #item.data.prix_fabrication="{ item }">
						{{ parseFloat(item.data.prix_fabrication).toFixed(2) }} €
					</template>
					<template #item.data.prix_emballage="{ item }"> {{ parseFloat(item.data.prix_emballage).toFixed(2) }} € </template>
					<template #item.data.prix_ht="{ item }"> {{ parseFloat(item.data.prix_ht).toFixed(2) }} € </template>
					<template #item.data.prix_ttc="{ item }"> {{ parseFloat(item.data.prix_ttc).toFixed(2) }} € </template>
					<template #item.action="{ item }">
						<v-btn depressed class="yellow--bg border-shaped" @click="getFacture(item.id)">
							<v-icon>mdi-file-pdf-box</v-icon>
						</v-btn>
						<v-btn color="red" class="border-shaped ml-2" depressed @click="deleteHistoryLine(item.id)">
							<v-icon> mdi-delete </v-icon>
						</v-btn>
					</template>
					<template #item.datetime="{ item }">
						{{ new Date(item.datetime).toLocaleString() }}
					</template>
					<template #item.data.orderPoints="{ item }">
						{{ item.data.orderPoints.length }}
					</template>
					<template v-slot:expanded-item="{ headers, item }">
						<td :colspan="headers.length">
							<v-card-text v-for="orderPoint in item.data.orderPoints" :key="orderPoint.id">
								<v-row>
									<v-col cols="12" md="6">
										<div>{{ $t('address') }}</div>
										<v-divider class="my-1"></v-divider>
										<div>
											{{ $t('address.country') }} :
											{{ $i18n.locale === 'fr' ? orderPoint.address.country.name : orderPoint.address.country.en_name }}
										</div>
										<div>
											{{ $t('address.type') }} :
											{{ $i18n.locale === 'fr' ? orderPoint.address.address_type.name : orderPoint.address.address_type.en_name }}
										</div>
										<div>{{ $t('address.postal_code') }} : {{ orderPoint.address.code_postal }}</div>
										<div>
											{{ $t('order.force_caisse') }} : {{ item.data.options.force_caisse ? $t('global.yes') : $t('global.no') }}
										</div>
									</v-col>
									<v-divider vertical></v-divider>
									<v-col cols="12" md="6">
										<div>{{ $t('products') }}</div>
										<v-divider class="my-1"></v-divider>
										<v-data-table
											:items="orderPoint.lines"
											:headers="[
												{ text: $t('product.title'), value: 'product.title' },
												{ text: $t('product.page_count'), value: 'product.page_count' },
												{ text: $t('order.line.quantity'), value: 'quantity' },
											]"
											hide-default-footer
											dense
											show-expand
											single-expand
											:expanded.sync="productExpanded"
										>
											<template #no-data>
												<div class="font-italic text--secondary">Aucun produit</div>
											</template>
											<template v-slot:expanded-item="{ headers, item }">
												<td :colspan="headers.length">
													<v-card-text class="text-caption">
														<div>{{ $t('product.title') }} : {{ item.product.title }}</div>
														<div>{{ $t('product.weight') }} : {{ item.product.weight }} g</div>
														<div>{{ $t('product.thickness') }} : {{ item.product.epaisseur }} mm</div>
														<div>{{ $t('product.page_count') }} : {{ item.product.page_count }}</div>
														<div>
															{{ $t('product.finition') }} :
															{{ $i18n.locale === 'fr' ? item.product.finition?.name : item.product.finition?.en_name }}
														</div>
														<div>
															{{ $t('product.impression') }} :
															{{ $i18n.locale === 'fr' ? item.product.impression?.name : item.product.impression?.en_name }}
														</div>
														<div>
															{{ $t('product.format') }} :
															{{ $i18n.locale === 'fr' ? item.product.format?.name : item.product.format?.en_name }}
														</div>
														<div>
															{{ $t('product.category') }} :
															{{ $i18n.locale === 'fr' ? item.product.category?.name : item.product.category?.en_name }}
														</div>
														<div>
															{{ $t('product.inner_paper') }} :
															{{
																$i18n.locale === 'fr'
																	? item.product.papier_interieur?.papier.name
																	: item.product.papier_interieur?.papier.en_name
															}}
														</div>
														<div>
															{{ $t('product.cover_paper') }} :
															{{
																$i18n.locale === 'fr'
																	? item.product.papier_couverture?.papier.name
																	: item.product.papier_couverture?.papier.en_name
															}}
														</div>
														<div>
															{{ $t('product.lamination') }} :
															{{ $i18n.locale === 'fr' ? item.product.pelliculage?.name : item.product.pelliculage?.en_name }}
														</div>
														<div>{{ $t('product.recto_verso') }} : {{ item.product.recto_verso ? 'Oui' : 'Non' }}</div>
														<div>{{ $t('product.lost_edges') }} : {{ item.product.bords_perdus ? 'Oui' : 'Non' }}</div>
													</v-card-text>
												</td>
											</template>
										</v-data-table>
									</v-col>
								</v-row>
							</v-card-text>
						</td>
					</template>
				</v-data-table>
			</v-sheet>
		</v-dialog>
		<v-container>
			<div class="d-flex align-center gap-2 mb-4">
				<v-menu offset-y open-on-hover>
					<template v-slot:activator="{ on, attrs }">
						<v-btn depressed class="yellow--bg border-shaped" v-bind="attrs" v-on="on">
							<v-icon>mdi-information-outline</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item> {{ $t('simulation_info') }} </v-list-item>
					</v-list>
				</v-menu>

				<v-btn depressed class="yellow--bg border-shaped" @click="showHistory = true">
					<v-icon>mdi-history</v-icon>
					{{ $t('history') }}
				</v-btn>
			</div>
			<v-tabs v-model="tab" grow color="black" slider-color="#ffd600" slider-size="4">
				<v-tab href="#tab-monopoint">
					<span>{{ $t('monopoint') }}</span>
				</v-tab>
				<v-tab href="#tab-multipoint">
					<span>{{ $t('multipoint') }}</span>
				</v-tab>
			</v-tabs>

			<v-tabs-items v-model="tab">
				<v-tab-item value="tab-monopoint">
					<ViewMonoPoint />
				</v-tab-item>
				<v-tab-item value="tab-multipoint">
					<ViewMultiPoint />
				</v-tab-item>
			</v-tabs-items>
		</v-container>
	</div>
</template>

<script>
	import ViewMultiPoint from '../components/simulation/ViewMultiPoint.vue';
	import ViewMonoPoint from '../components/simulation/ViewMonoPoint.vue';

	import PageTitle from '../components/info/PageTitle.vue';
	import axios from 'axios';
	import { nanoid } from 'nanoid';

	import { EventBus } from '../utils';

	export default {
		name: 'Simulation',
		components: { PageTitle, ViewMultiPoint, ViewMonoPoint },
		data() {
			return {
				tab: null,
				showHistory: false,
				historyExpanded: [],
				productExpanded: [],
				loadingHistory: false,
				loadingPDF: false,
				history: [],
				historyHeaders: [
					{ text: '', value: 'data-table-expand' },
					{ text: 'Action', value: 'action' },
					{ text: 'Date', value: 'datetime' },
					{ text: this.$t('order_points'), value: 'data.orderPoints' },
					{ text: this.$t('order.price.shipping'), value: 'data.prix_livraison' },
					{ text: this.$t('order.price.production'), value: 'data.prix_fabrication' },
					{ text: this.$t('order.price.packaging'), value: 'data.prix_emballage' },
					{ text: this.$t('order.price.ht'), value: 'data.prix_ht' },
					{ text: this.$t('order.price.ttc'), value: 'data.prix_ttc' },
				],
				items: [
					{
						text: 'Rapidbook',
						disabled: false,
						to: '/',
					},
					{
						text: 'Dashboard',
						disabled: false,
						to: 'dashboard',
					},
					{
						text: 'Simulation',
						disabled: true,
						to: '',
					},
				],
			};
		},
		computed: {
			footerProps: function () {
				return {
					'items-per-page-options': [5, 10, 15, 30, 50, 100, -1],
					itemsPerPageText: this.$t('global.items_per_page'),
				};
			},
		},
		methods: {
			getHistory() {
				this.loadingHistory = true;
				axios
					.get(process.env.VUE_APP_API_URL + '/simulation/history', {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then((r) => {
						this.history = r.data.items;
					})
					.catch((e) => {
						EventBus.$emit('notif', e.response.data.message, 'error');
					})
					.finally(() => {
						this.loadingHistory = false;
					});
			},
			getFacture(id) {
				this.loadingPDF = true;
				axios
					.get(process.env.VUE_APP_API_URL + '/simulation/history', {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
						params: {
							id: id,
							pdf: true,
						},
						responseType: 'blob',
					})
					.then((response) => {
						// Create a blob from the response data
						const blob = new Blob([response.data], { type: 'application/pdf' });

						// Create a link element to trigger the download
						const link = document.createElement('a');
						link.href = window.URL.createObjectURL(blob);
						link.download = 'simulation-' + new Date().toISOString() + '.pdf'; // Set the file name
						link.click();

						// Clean up
						window.URL.revokeObjectURL(link.href);
					})
					.catch((e) => {
						EventBus.$emit('notif', e.response.data.message, 'error');
					})
					.finally(() => {
						this.loadingPDF = false;
					});
			},
			deleteHistoryLine(id) {
				axios
					.delete(process.env.VUE_APP_API_URL + '/simulation/history/' + id, {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then((r) => {
						EventBus.$emit('notif', this.$t('line_deleted'), 'success');
						this.getHistory();
					})
					.catch((e) => {
						EventBus.$emit('notif', e.response.data.message, 'error');
					});
			},
		},
		watch: {
			showHistory() {
				if (this.showHistory) {
					this.getHistory();
				}
			},
		},
	};
</script>

<style lang="scss" scoped></style>

<i18n lang="json">
{
	"fr": {
		"title": "Simulation Tarifaire",
		"order_points": "Points de livraison",
		"monopoint": "Un seul point de livraison",
		"multipoint": "Plusieurs points de livraison",
		"history": "Vos simulations passées",
		"no_history": "Aucun historique",
		"simulation_info": "L'historique est stocké sur nos serveurs cependant la librairie est sauvegardée dans votre navigateur",
		"line_deleted": "Ligne supprimée",
		"products": "Produits",
		"address": "Adresse"
	},
	"en": {
		"title": "Pricing Simulation",
		"order_points": "Delivery points",
		"monopoint": "One delivery point",
		"multipoint": "Multiple delivery points",
		"history": "Your past simulations",
		"no_history": "No history",
		"simulation_info": "The history is stored on our servers however the library is saved in your browser",
		"line_deleted": "Line deleted",
		"products": "Products",
		"address": "Address"
	}
}
</i18n>
