module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "cart_not_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, le panier n'est pas vide !"])}
      },
      "en": {
        "cart_not_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning, the cart is not empty !"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"cart_not_empty":"Attention, le panier n\u0027est pas vide !"},"en":{"cart_not_empty":"Warning, the cart is not empty !"}}')
  delete Component.options._Ctor
  
}
