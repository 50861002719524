<template>
	<div class="font-weight-light">
		<section id="section-0" class="home">
			<div :height="height" class="min-h-screen-resized tw-flex">
				<v-img
					class="ukiyo tw-w-full h-screen-resized tw-absolute"
					src="@/assets/img/accueil/image_accueil-gigapixel-high fidelity v2-2x.jpeg"
				/>
				<v-container class="tw-z-0" fluid>
					<v-row>
						<v-col class="tw-flex tw-items-center tw-justify-end min-h-screen-resized">
							<v-sheet
								class="tw-w-full border-card-shaped lg:tw-w-9/12 xl:tw-w-6/12 rellax xl:tw-mr-24"
								data-rellax-percentage="0.5"
								data-rellax-speed="7"
							>
								<v-row>
									<v-col cols="2" sm="1">
										<div class="tw-w-12 tw-h-full tw-bg-yellow-400 tw-rounded-tr-3xl tw-rounded-bl-3xl"></div
									></v-col>
									<v-col class="tw-py-16 tw-pr-16">
										<div
											class="tw-my-6 tw-font-bold tw-text-yellow-400 md:tw-text-3xl tw-text-2xl tw-uppercase"
											v-html="$t('home-1')"
										></div>
										<div class="tw-w-24 tw-h-4 tw-my-5 tw-bg-yellow-400 md:tw-w-48 tw-rounded-tl-xl tw-rounded-br-xl"></div>
										<div class="tw-flex tw-flex-col tw-gap-10">
											<div class="tw-font-bold md:tw-text-xl tw-text-lg" v-html="$t('home-2')"></div>
											<div class="tw-text-center">
												<v-btn
													:to="{ name: 'Dashboard' }"
													large
													class="yellow--bg border-shaped tw--mb-8"
													depressed
													v-if="$store.getters.isAuthenticated"
												>
													{{ $t('menu.dashboard') }}
												</v-btn>
												<v-btn :to="{ name: 'Login' }" large class="yellow--bg border-shaped tw--mb-8" depressed v-else>
													{{ $t('menu.login') }}
												</v-btn>
											</div>
										</div>
									</v-col>
								</v-row>
							</v-sheet>
						</v-col>
					</v-row>
				</v-container>
			</div>
		</section>
		<section id="section-1">
			<v-container class="tw-flex tw-items-center min-h-screen-resized" fluid>
				<v-row>
					<v-col cols="9" class="tw-mx-auto">
						<div class="tw-font-black tw-text-yellow-400 md:tw-text-3xl tw-text-2xl tw-uppercase" v-html="$t('home-3')"></div>
						<div class="tw-w-24 tw-h-4 tw-my-5 tw-bg-yellow-400 md:tw-w-48 tw-rounded-tl-xl tw-rounded-br-xl"></div>
						<div class="tw-text-lg tw-font-bold" v-html="$t('home-4')"></div>
						<v-row class="tw-mt-5 tw-text-justify">
							<v-col cols="12" md="6" v-html="$t('home-5')"></v-col>
							<v-col cols="12" md="6" v-html="$t('home-6')"></v-col>
						</v-row>
						<v-row>
							<v-col cols="12" md="6" v-html="$t('home-7')"></v-col>
							<v-col cols="12" md="6" v-html="$t('home-8')"></v-col>
						</v-row>
						<v-row class="mt-10">
							<v-col cols="12" md="">
								<div class="tw-flex tw-flex-col tw-gap-5">
									<v-img src="@/assets/img/home_one_page/01_arguments_marketing/livraison.svg" class="tw-h-20" contain />
									<div class="tw-font-bold tw-text-center tw-uppercase" v-html="$t('home-9')"></div>
									<div class="tw-text-center" v-html="$t('home-10')"></div>
								</div>
							</v-col>
							<v-col cols="12" md="1">
								<div
									class="tw-w-full tw-h-4 tw-mx-auto tw-bg-yellow-400 tw-rounded-tr-none tw-rounded-bl-none md:tw-h-full md:tw-w-4 md:tw-rounded-tr-3xl md:tw-rounded-bl-3xl md:tw-rounded-tl-none md:tw-rounded-br-none tw-rounded-tl-3xl tw-rounded-br-3xl"
								></div>
							</v-col>
							<v-col cols="12" md="">
								<div class="tw-flex tw-flex-col tw-gap-5">
									<v-img src="@/assets/img/home_one_page/01_arguments_marketing/un_exemplaire.svg" class="tw-h-20" contain />
									<div class="tw-font-bold tw-text-center tw-uppercase" v-html="$t('home-11')"></div>
									<div class="tw-text-center" v-html="$t('home-12')"></div>
								</div>
							</v-col>
							<v-col cols="12" md="1">
								<div
									class="tw-w-full tw-h-4 tw-mx-auto tw-bg-yellow-400 tw-rounded-tr-none tw-rounded-bl-none md:tw-h-full md:tw-w-4 md:tw-rounded-tr-3xl md:tw-rounded-bl-3xl md:tw-rounded-tl-none md:tw-rounded-br-none tw-rounded-tl-3xl tw-rounded-br-3xl"
								></div>
							</v-col>
							<v-col cols="12" md="">
								<div class="tw-flex tw-flex-col tw-gap-5">
									<v-img src="@/assets/img/home_one_page/01_arguments_marketing/prix_degressif.svg" class="tw-h-20" contain />
									<div class="tw-font-bold tw-text-center tw-uppercase" v-html="$t('home-13')"></div>
									<div class="tw-text-center" v-html="$t('home-14')"></div>
								</div>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</section>
		<v-container fluid>
			<div class="tw-relative tw-w-full tw-h-10 tw-my-5 tw-bg-yellow-400 tw-rounded-tl-3xl tw-rounded-br-3xl"></div>
		</v-container>
		<v-container class="tw-flex tw-items-center min-h-screen-resized" fluid>
			<v-row>
				<v-col cols="9" class="tw-mx-auto">
					<v-row class="tw-flex">
						<v-col cols="12" lg="6" class="order-1">
							<div class="tw-flex tw-items-center tw-gap-5">
								<div>
									<v-img src="@/assets/img/logo-corlet-2021.png" class="tw-w-20" contain />
								</div>
								<div
									class="tw-font-black tw-text-yellow-400 md:tw-text-3xl tw-text-2xl tw-uppercase"
									v-html="$t('home-15')"
								></div>
							</div>
							<div class="tw-w-24 tw-h-4 tw-my-5 tw-bg-yellow-400 md:tw-w-48 tw-rounded-tl-xl tw-rounded-br-xl"></div>
						</v-col>
						<v-col cols="12" lg="6" class="order-3 tw-place-self-end order-lg-2">
							<div class="tw-font-bold md:tw-text-3xl tw-text-2xl tw-uppercase" v-html="$t('home-20')"></div>
							<div class="tw-w-24 tw-h-4 tw-my-5 tw-bg-yellow-400 md:tw-w-48 tw-rounded-tl-xl tw-rounded-br-xl"></div>
						</v-col>
						<v-col cols="12" lg="6" class="order-2 order-lg-3">
							<div class="tw-flex tw-flex-col tw-gap-5 tw-text-justify">
								<div class="tw-font-bold md:tw-text-xl tw-text-lg" v-html="$t('home-16')"></div>
								<div v-html="$t('home-17')"></div>
								<div v-html="$t('home-18')"></div>
								<div v-html="$t('home-19')"></div>
							</div>
						</v-col>
						<v-col cols="12" lg="6" class="order-4">
							<div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-5">
								<div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-5">
									<div>
										<v-img src="@/assets/img/home_one_page/02_histoire/60ans_experience.svg" class="tw-w-32 tw-h-32" contain />
									</div>
									<div class="tw-font-bold tw-text-center" v-html="$t('home-21')"></div>
									<div class="tw-text-center" v-html="$t('home-22')"></div>
								</div>
								<div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-5">
									<div>
										<v-img src="@/assets/img/home_one_page/02_histoire/4_imprimeur.svg" class="tw-w-32 tw-h-32" contain />
									</div>
									<div class="tw-font-bold tw-text-center" v-html="$t('home-23')"></div>
									<div class="tw-text-center" v-html="$t('home-24')"></div>
								</div>
								<div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-5">
									<div>
										<v-img src="@/assets/img/home_one_page/02_histoire/collaborateur.svg" class="tw-w-32 tw-h-32" contain />
									</div>
									<div class="tw-font-bold tw-text-center" v-html="$t('home-25')"></div>
									<div class="tw-text-center" v-html="$t('home-26')"></div>
								</div>
								<div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-5">
									<div>
										<v-img src="@/assets/img/home_one_page/02_histoire/made_in_normandie.svg" class="tw-w-32 tw-h-32" contain />
									</div>
									<div class="tw-font-bold tw-text-center" v-html="$t('home-27')"></div>
									<div class="tw-text-center" v-html="$t('home-28')"></div>
								</div>
							</div>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-container>
		<section id="section-3">
			<div :height="height" class="min-h-screen-resized tw-flex">
				<v-img
					class="ukiyo tw-w-full h-screen-resized tw-absolute"
					src="@/assets/img/home_one_page/03_produit/image-gigapixel-high fidelity v2-2x.jpeg"
				></v-img>
				<div class="tw-absolute tw-right-0 tw-w-1/3 h-screen-resized tw-bg-white tw-pointer-events-none"></div>
				<v-container class="tw-z-0" fluid>
					<v-row>
						<v-col class="tw-flex tw-items-center tw-justify-end min-h-screen-resized">
							<v-sheet
								class="tw-w-full border-card-shaped lg:tw-w-9/12 xl:tw-w-6/12 rellax xl:tw-mr-24"
								data-rellax-percentage="0.5"
								data-rellax-speed="7"
							>
								<v-row>
									<v-col cols="2" sm="1">
										<div class="tw-w-12 tw-h-full tw-bg-yellow-400 tw-rounded-tr-3xl tw-rounded-bl-3xl"></div
									></v-col>
									<v-col class="tw-py-16 tw-pr-16">
										<div
											class="tw-font-bold tw-text-yellow-400 md:tw-text-3xl tw-text-2xl tw-uppercase"
											v-html="$t('home-29')"
										></div>
										<div class="tw-w-24 tw-h-4 tw-my-5 tw-bg-yellow-400 md:tw-w-48 tw-rounded-tl-xl tw-rounded-br-xl"></div>
										<div class="tw-flex tw-flex-col tw-gap-5 tw-text-justify">
											<div class="tw-font-bold md:tw-text-xl tw-text-lg" v-html="$t('home-30')"></div>
											<div v-html="$t('home-31')"></div>
											<div v-html="$t('home-32')"></div>
										</div>
									</v-col>
								</v-row>
							</v-sheet>
						</v-col>
					</v-row>
				</v-container>
			</div>
		</section>
		<section id="section-4">
			<div
				class="tw-absolute tw-right-0 tw-hidden tw-w-1/3 h-screen-resized tw-bg-yellow-400 tw-pointer-events-none lg:tw-block"
			></div>
			<div
				class="tw-absolute tw-right-0 tw-hidden tw-w-1/12 h-screen-resized tw-bg-white tw-pointer-events-none lg:tw-block"
			></div>
			<v-container class="tw-flex tw-items-center min-h-screen-resized" fluid>
				<v-row>
					<v-col cols="9" class="tw-mx-auto">
						<div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-items-center">
							<v-col class="tw-flex tw-flex-col tw-justify-center" cols="12">
								<div
									class="tw-font-black tw-text-yellow-400 md:tw-text-3xl tw-text-2xl tw-uppercase"
									v-html="$t('home-33')"
								></div>
								<div class="tw-flex tw-flex-col tw-gap-5 tw-text-justify">
									<div class="tw-w-24 tw-h-4 tw-my-5 tw-bg-yellow-400 md:tw-w-48 tw-rounded-tl-xl tw-rounded-br-xl"></div>
									<div class="tw-font-bold md:tw-text-xl tw-text-lg" v-html="$t('home-34')"></div>
									<div v-html="$t('home-35')"></div>
									<v-btn class="yellow--bg border-shaped" to="/contact" depressed>
										{{ $t('menu.contact') }}
									</v-btn>
								</div>
							</v-col>
							<v-col>
								<v-img
									class="vanilla-tilt"
									src="@/assets/img/home_one_page/04_equipe/photo_rapidbook-gigapixel-high fidelity v2-2x.jpg"
								/>
							</v-col>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</section>
		<section id="section-5">
			<div :height="height" class="min-h-screen-resized tw-flex">
				<v-img
					class="ukiyo tw-w-full h-screen-resized tw-absolute"
					src="@/assets/img/home_one_page/05_ecoresponsable/image-gigapixel-high fidelity v2-2x.jpeg"
				/>
				<div class="tw-absolute tw-right-0 tw-w-1/3 h-screen-resized tw-bg-white tw-pointer-events-none"></div>
				<v-container fluid>
					<v-row>
						<v-col class="tw-flex tw-items-center tw-justify-end min-h-screen-resized">
							<v-sheet
								class="tw-w-full border-card-shaped lg:tw-w-9/12 xl:tw-w-6/12 rellax xl:tw-mr-24"
								data-rellax-percentage="0.5"
								data-rellax-speed="7"
							>
								<v-row>
									<v-col cols="2" sm="1">
										<div class="tw-w-12 tw-h-full tw-bg-yellow-400 tw-rounded-tr-3xl tw-rounded-bl-3xl"></div
									></v-col>
									<v-col class="tw-py-16 tw-pr-16">
										<div
											class="tw-font-bold tw-text-yellow-400 md:tw-text-3xl tw-text-2xl tw-uppercase"
											v-html="$t('home-36')"
										></div>
										<div class="tw-w-24 tw-h-4 tw-my-5 tw-bg-yellow-400 md:tw-w-48 tw-rounded-tl-xl tw-rounded-br-xl"></div>
										<div class="tw-flex tw-flex-col tw-gap-5 tw-text-justify">
											<div class="tw-font-bold md:tw-text-xl tw-text-lg" v-html="$t('home-37')"></div>
											<div v-html="$t('home-38')"></div>
										</div>
									</v-col>
								</v-row>
							</v-sheet>
						</v-col>
					</v-row>
				</v-container>
			</div>
		</section>
	</div>
</template>

<script lang="js">
	import rellax from 'rellax'
	import * as VanillaTilt from 'vanilla-tilt'
	import { useWindowSize } from '@vueuse/core';
	import Ukiyo from "ukiyojs";

	export default {
		name: 'Home',
		metaInfo: {
			title: 'Rapidbook - Home',
			metas: [
				{
					name: 'com.silverpop.brandeddomains',
					content: 'www.pages06.net,corlet.fr,rapidbook.fr,www.corlet.fr,www.rapidbook.fr'
				}
			],
			script: [
				{
					src: 'https://www.sc.pages06.net/lp/static/js/iMAWebCookie.js?40625c3e-18bd96a8009-3c9d767ebba0be65b5fa68bf26a6ee93&h=www.pages06.net'
				}
			]
		},
		props: [],
		data() {
			const { width, height } = useWindowSize()

			return {
				width: width,
				height: ['xl', 'lg', 'md'].includes(this.$vuetify.breakpoint.name) ? height.value - 64 : height.value - 56
			}
		},
		mounted() {
			new rellax('.rellax')
			new Ukiyo(".ukiyo", {
				scale: 1.3
			})
			VanillaTilt.init(document.querySelectorAll('.vanilla-tilt'), {
				reverse: true,
				max: 5,
				speed: 2000,
				glare: true,
				"max-glare": 0.5
			})
		},
		created() {
			window.addEventListener('resize', this.onResize)
		},
		destroyed() {
			window.removeEventListener('resize', this.onResize)
		},
		methods: {
		}
	}
</script>

<style scoped>
	.border-card-shaped {
		border-bottom-left-radius: 50px !important;
		border-bottom-right-radius: 20px !important;
		border-top-right-radius: 5px !important;
	}
</style>

<i18n lang="json">
{
	"fr": {
		"home-1": "RAPIDBOOK : TOURNEZ LA PAGE EN<br>UN CLIC !",
		"home-2": "Le nouvel outil d'impression à la demande des professionnels de l'édition",
		"home-3": "TOURNEZ LA PAGE AVEC RAPIDBOOK",
		"home-4": "Le nouveau système d'impression à la demande",
		"home-5": "Une question primordiale d'abord, <strong>Rapidbook</strong>, c'est quoi ? Il s'agit tout bonnement de la nouvelle création du groupe Corlet, un <strong>système d'impression à la demande</strong>, ou <strong>Print on Demand (PoD)</strong> pour les anglophones, destiné aux éditeurs.",
		"home-6": "Pas d'attente pour le devis, il est <strong>immédiat et en ligne</strong>. Quant aux prix, ils sont <strong>dégressifs</strong>: plus la commande est importante et plus le prix est avantageux. Le reste, c'est nous qui nous en occupons avec une livraison assurée en moyenne 5 jours plus tard (ouvrés - hors contraintes de rendez-vous diffuseurs).",
		"home-7": "Avec Rapidbook, plus de problème de livres qui s'accumulent en réserve et qui prennent la poussière : d'un seul clic, les éditeurs peuvent refaire leur stock quand ils en ont besoin, même pour un <strong>seul exemplaire</strong>, et cela <strong>24h/24, 7j/7</strong>.",
		"home-8": "<strong>Simple</strong> vous avez dit ?",
		"home-9": "<strong>LIVRAISON EN 48H/72H</strong>",
		"home-10": "Nos partenaires nous permettent d'assurer une livraison en moins de 72h dès la fin de fabrication.",
		"home-11": "<strong>À PARTIR DE 1 EXEMPLAIRE</strong>",
		"home-12": "Fini le stock ! Il est possible de commander à partir d'un exemplaire.",
		"home-13": "<strong>PRIX DÉGRESSIFS</strong>",
		"home-14": "Plus la commande est importante, plus le prix est avantageux.",
		"home-15": "NOTRE HISTOIRE",
		"home-16": "Rapidbook, une nouvelle page de l'histoire Corlet",
		"home-17": "Derrière Rapidbook, il y a le groupe Corlet. Sa réputation n'est plus à faire aujourd'hui: créée en <strong>1960</strong>, l'ancienne petite entreprise a petit à petit gravi les échelons jusqu'à devenir <strong>4<sup>e</sup> imprimerie de France.</strong>",
		"home-18": "Un hasard ? Non, car l'histoire du groupe Corlet montre bien que c'est grâce à sa <strong>détermination</strong> et ses nombreuses <strong>innovations</strong> qu'il a réussi à acquérir ce statut. Innovations dont le site Rapidbook fait aujourd'hui partie. Bénéficiant à la fois du <strong>support technique</strong> du groupe Corlet, d'un <strong>savoir-faire</strong> déjà présent grâce à plusieurs décennies d'expérience et d'une équipe <strong>dynamique et performante</strong>, nul doute que Rapidbook est un site déjà à la page.",
		"home-19": "Vous l'aurez donc compris le groupe Corlet et Rapidbook travaillent main dans la main pour vous apporter un produit abouti, complet, et de qualité : un vrai <strong>best-seller</strong> en somme.",
		"home-20": "4 BONNES RAISONS<br />DE NOUS CHOISIR",
		"home-21": "<strong>60 ANS D'EXPÉRIENCE</strong>",
		"home-22": "Corlet a beau être sexagénaire, ça ne l'empêche pas d'être toujours là pour ses clients.",
		"home-23": "<strong>3<sup>e</sup> IMPRIMEUR FRANÇAIS</strong>",
		"home-24": "Avec 2 millions d'imprimés et 150 commandes par jour, Corlet se hisse sur le podium de l'impression de livres ! ",
		"home-25": "<strong>360 COLLABORATEURS</strong>",
		"home-26": "Armé de tous nos collaborateurs, ensemble nous ferons devenir vos livres réalité.",
		"home-27": "<strong>MADE IN NORMANDIE</strong>",
		"home-28": "Nous favorisons une production locale pour une meilleure protection de la planète. C'est beau non ?",
		"home-29": "POUR L'ÉPANOUISSEMENT DES ÉDITEURS",
		"home-30": "Rapidbook, une gestion en toute liberté",
		"home-31": "À partir d'un exemplaire chez Rapidbook, chaque livre est fait <strong>sur mesure.</strong> Du petit livre de poche indispensable en cas de manque de place (11 x 18 cm) au pavé rempli de connaissances infinies (21 x 29,7 cm), c'est à vous de choisir le format. À votre libre choix aussi, le nombre de pages de votre précieux ouvrage, à partir de 64 pages.",
		"home-32": "Vous voulez donner un certain style à votre livre ? N'hésitez pas à choisir votre propre papier entre bouffant 80 g blanc ou offset 80 g. Enfin, vous pouvez également opter pour un pelliculage mat ou brillant. Grâce à Rapidbook, ayez enfin un livre <strong>à votre image</strong> !",
		"home-33": "NOTRE ÉQUIPE",
		"home-34": "Pour vous accompagner au mieux",
		"home-35": "Notre équipe, c'est avant tout des personnes déjà rodées au monde de l'impression après avoir travaillé plusieurs années au sein du groupe Corlet. <strong>Disponibles, organisées, efficaces</strong>, elles sont même capables de lire entre les lignes. À votre écoute et prêt à répondre à toutes vos questions, n'hésitez pas à les contacter en cas de problème !",
		"home-36": "UNE PRODUCTION ÉCO-RESPONSABLE",
		"home-37": "Jeter des mots sur le papier, oui, mais qu'en est-il de le jeter ?",
		"home-38": "Dans la digne lignée de Corlet, Rapidbook s'inscrit aussi comme une entreprise dédiée à la <strong>protection de l'environnement</strong>. Utilisant un papier issu d'une forêt gérée durablement et bénéficiant à la fois de la certification <strong>PEFC</strong> et du <strong>label Imprim'Vert</strong>, Rapidbook a à coeur de vous présenter un produit éco-responsable. Mais cet engagement va au-delà du papier, Rapidbook a aussi pris ses quartiers chez Corlet Numeric, un <strong>bâtiment écologique</strong> dont la toiture est équipée de 777m² de <strong>panneaux photovoltaïques</strong> et où les eaux usées sont traitées. Enfin, Rapidbook privilégie aussi le <strong>Made in France</strong> car, comme vous l'aurez deviné, toute notre production prend place en <strong>Normandie</strong> !"
	},
	"en": {
		"home-1": "RAPIDBOOK: TURN THE PAGE IN<br>A CLICK!",
		"home-2": "The new print-on-demand tool for publishing professionals",
		"home-3": "TURN THE PAGE WITH RAPIDBOOK",
		"home-4": "The new print-on-demand system",
		"home-5": "First things first, what is <strong>Rapidbook</strong>? It is simply the new creation of the Corlet group, a <strong>print-on-demand</strong> system, or <strong>PoD</strong> for English speakers, intended for publishers.",
		"home-6": "No waiting for the quote, it is <strong>immediate and online</strong>. As for the prices, they are <strong>decreasing</strong>: the larger the order, the more advantageous the price. We take care of the rest with delivery guaranteed on average 5 days later (working days - excluding distributor appointment constraints).",
		"home-7": "With Rapidbook, no more problems with books piling up in stock and gathering dust: with a single click, publishers can replenish their stock when they need it, even for a <strong>single copy</strong>, and this <strong>24/7</strong>.",
		"home-8": "Did you say <strong>simple</strong>?",
		"home-9": "<strong>DELIVERY IN 48H/72H</strong>",
		"home-10": "Our partners allow us to ensure delivery in less than 72 hours from the end of manufacturing.",
		"home-11": "<strong>FROM 1 COPY</strong>",
		"home-12": "No more stock! It is possible to order from one copy.",
		"home-13": "<strong>DECREASING PRICES</strong>",
		"home-14": "The larger the order, the more advantageous the price.",
		"home-15": "OUR HISTORY",
		"home-16": "Rapidbook, a new page in Corlet's history",
		"home-17": "Behind Rapidbook is the Corlet group. Its reputation is well established today: created in <strong>1960</strong>, the former small company gradually climbed the ladder to become the <strong>4<sup>th</sup> largest printing company in France.</strong>",
		"home-18": "A coincidence? No, because the history of the Corlet group clearly shows that it is thanks to its <strong>determination</strong> and its numerous <strong>innovations</strong> that it has managed to acquire this status. Innovations of which the Rapidbook site is now a part. Benefiting from both the <strong>technical support</strong> of the Corlet group, <strong>know-how</strong> already present thanks to several decades of experience and a <strong>dynamic and efficient</strong> team, there is no doubt that Rapidbook is a site already up to date.",
		"home-19": "As you will have understood, the Corlet group and Rapidbook work hand in hand to bring you a complete, high-quality and successful product: a real <strong>best-seller</strong> in short.",
		"home-20": "4 GOOD REASONS<br />TO CHOOSE US",
		"home-21": "<strong>60 YEARS OF EXPERIENCE</strong>",
		"home-22": "Corlet may be a senior citizen, but that doesn't stop it from always being there for its customers.",
		"home-23": "<strong>3<sup>rd</sup> FRENCH PRINTER</strong>",
		"home-24": "With 2 million printed items and 150 orders per day, Corlet is on the podium of book printing!",
		"home-25": "<strong>360 EMPLOYEES</strong>",
		"home-26": "Armed with all our collaborators, together we will make your books a reality.",
		"home-27": "<strong>MADE IN NORMANDY</strong>",
		"home-28": "We favor local production for better protection of the planet. Isn't that beautiful?",
		"home-29": "FOR THE DEVELOPMENT OF PUBLISHERS",
		"home-30": "Rapidbook, management in complete freedom",
		"home-31": "Starting from one copy at Rapidbook, each book is <strong>custom-made.</strong> From the small paperback essential in case of lack of space (11 x 18 cm) to the tome filled with infinite knowledge (21 x 29.7 cm), it's up to you to choose the format. You can also freely choose the number of pages of your precious book, starting from 64 pages.",
		"home-32": "Want to give your book a certain style? Don't hesitate to choose your own paper between bulky 80 g white or offset 80 g. Finally, you can also opt for a matt or gloss lamination. Thanks to Rapidbook, finally have a book <strong>in your image</strong>!",
		"home-33": "OUR TEAM",
		"home-34": "To best support you",
		"home-35": "Our team is above all people who are already familiar with the world of printing after having worked for several years within the Corlet group. <strong>Available, organized, efficient</strong>, they are even able to read between the lines. At your disposal and ready to answer all your questions, do not hesitate to contact them in case of a problem!",
		"home-36": "AN ECO-RESPONSIBLE PRODUCTION",
		"home-37": "Throwing words on paper, yes, but what about throwing it away?",
		"home-38": "In the worthy lineage of Corlet, Rapidbook is also a company dedicated to the <strong>protection of the environment</strong>. Using paper from a sustainably managed forest and benefiting from both <strong>PEFC</strong> certification and the <strong>Imprim'Vert label</strong>, Rapidbook is committed to presenting you with an eco-responsible product. But this commitment goes beyond paper, Rapidbook has also taken up residence at Corlet Numeric, an <strong>ecological building</strong> whose roof is equipped with 777m² of <strong>photovoltaic panels</strong> and where wastewater is treated. Finally, Rapidbook also favors <strong>Made in France</strong> because, as you may have guessed, all our production takes place in <strong>Normandy</strong>!"
	}
}
</i18n>
