var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"admin"},[_c('v-tabs',{attrs:{"show-arrows":"","vertical":_vm.$vuetify.breakpoint.lgAndUp,"centered":_vm.$vuetify.breakpoint.mdAndDown,"color":"black","slider-color":"#ffd600","slider-size":"4"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.items.filter((i) => i.condition)),function(item,i){return _c('v-tab',{key:i,staticClass:"justify-start"},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.name)+" ")],1)}),_c('v-tabs-items',{attrs:{"touchless":"","vertical":_vm.$vuetify.breakpoint.lgAndUp},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('AccueilAdmin')],1),_c('v-tab-item',[_c('ViewAdmin',{attrs:{"item-type":"users","headers":[
						{ text: 'Action', value: 'action' },
						{ text: 'Activé/Désactivé', value: 'enabled' },
						{ text: 'Id', align: 'start', value: 'id' },
						{ text: 'Pseudo', value: 'username' },
						{ text: 'Nom', value: 'real_name' },
						{ text: 'Email', value: 'email' },
						{ text: 'Téléphone', value: 'phone' },
						{ text: 'Code Compta', value: 'code_compta' },
						{ text: 'Code Masterprint', value: 'code_masterprint' },
						{ text: 'Crée le', value: 'created_at' },
						{ text: 'Dernière connexion', value: 'last_login' },
						{ text: 'Dernière activité', value: 'last_activity' },
						{ text: 'Parent', value: 'user_parent.username' },
						{ text: 'Roles', value: 'roles' },
					],"per-page":50,"sort-by":"last_activity"}})],1),_c('v-tab-item',[_c('ViewAdminServerSide',{attrs:{"item-type":"products","headers":[
						{ text: 'Action', value: 'action' },
						{ text: 'Activé/Désactivé', value: 'enabled' },
						{ text: 'Id', align: 'start', value: 'id' },
						{ text: 'Uid', value: 'uid' },
						{ text: 'ISBN', value: 'isbn' },
						{ text: 'Référence', value: 'reference' },
						{ text: 'Catégorie', value: 'category.name' },
						{ text: 'Titre', value: 'title' },
						{ text: 'Auteur', value: 'author' },
						{ text: 'Nombre de pages', value: 'page_count' },
						{ text: 'Epaisseur', value: 'epaisseur' },
						{ text: 'Poids', value: 'weight' },
						{ text: 'Format', value: 'format.name' },
						{ text: 'Impression', value: 'impression.name' },
						{ text: 'Pelliculage', value: 'pelliculage.name' },
						{
							text: 'Papier couverture',
							value: 'papier_couverture.papier.name',
						},
						{
							text: 'Papier interieur',
							value: 'papier_interieur.papier.name',
						},
						{ text: 'Etat', value: 'f1.name' },
						{ text: 'Envoyé au serveur', value: 'f2' },
						{ text: 'Acheve', value: 'acheve.name' },
						{ text: 'Finition', value: 'finition.name' },
						{ text: 'Version', value: 'version' },
						{ text: 'Mis à jour le', value: 'updated_at' },
						{ text: 'Crée le', value: 'created_at' },
						{ text: 'Utilisateur', value: 'user' },
					],"per-page":50}})],1),_c('v-tab-item',[_c('ViewAdminServerSide',{attrs:{"item-type":"orders","headers":[
						{ text: 'Action', value: 'action' },
						{ text: 'Activé/Désactivé', value: 'enabled' },
						{ text: 'Id', align: 'start', value: 'id' },
						{ text: 'Uid', align: 'start', value: 'uid' },
						{ text: 'Etat', value: 'f1.name' },
						{ text: 'Prix Fabrication', value: 'prix_fabrication' },
						{ text: 'Prix Livraison', value: 'prix_livraison' },
						{ text: 'Prix Emballage', value: 'prix_emballage' },
						{ text: 'Prix HT', value: 'prix_ht' },
						{ text: 'Prix TTC', value: 'prix_ttc' },
						{ text: 'Crée le', value: 'created_at' },
						{ text: 'Mis à jour le', value: 'updated_at' },
						{ text: 'Utilisateur', value: 'user' },
						{ text: 'Lignes de commande', value: 'lignes_commande' },
					],"per-page":50}})],1),_c('v-tab-item',[_c('ViewAdminServerSide',{attrs:{"item-type":"lines","headers":[
						{ text: 'Action', value: 'action' },
						{ text: 'Activé/Désactivé', value: 'enabled' },
						{ text: 'Id', align: 'start', value: 'id' },
						{ text: 'Produit', value: 'product' },
						{ text: 'Commande', value: 'commande.uid' },
						{ text: 'Etat', value: 'f1.name' },
						{ text: 'Quantité', value: 'quantity' },
						{ text: 'Commentaire', value: 'comment' },
						{ text: 'Crée le', value: 'created_at' },
						{ text: 'Mis à jour le', value: 'updated_at' },
					],"per-page":50}})],1),_c('v-tab-item',[_c('ViewAdminServerSide',{attrs:{"item-type":"addresses","headers":[
						{ text: 'Action', value: 'action' },
						{ text: 'Activé/Désactivé', value: 'enabled' },
						{ text: 'Id', align: 'start', value: 'id' },
						{ text: 'Adresse', value: 'address' },
						{ text: 'Complément', value: 'complement' },
						{ text: 'Ville', value: 'city' },
						{ text: 'Code Postal', value: 'code_postal' },
						{ text: 'Pays', value: 'country.name' },
						{ text: 'Contact', value: 'contact' },
						{ text: 'Raison Sociale', value: 'raison_sociale' },
						{ text: 'Email', value: 'email' },
						{ text: 'Fixe', value: 'phone_fix' },
						{ text: 'Mobile', value: 'phone_mobile' },
						{ text: 'Type', value: 'address_type.name' },
						{ text: 'Commentaire', value: 'comment' },
						{ text: 'Version', value: 'version' },
						{ text: 'Crée le', value: 'created_at' },
						{ text: 'Mis à jour le', value: 'updated_at' },
						{ text: 'Utilisateur', value: 'user' },
					],"per-page":50}})],1),_c('v-tab-item',[_c('ViewAdmin',{attrs:{"item-type":"formats","headers":[
						{ text: 'Action', value: 'action' },
						{ text: 'Activé/Désactivé', value: 'enabled' },
						{ text: 'Id', align: 'start', value: 'id' },
						{ text: 'Libellé', value: 'name' },
						{ text: 'Hauteur', value: 'height' },
						{ text: 'Largeur', value: 'width' },
						{ text: 'Utilisateur', value: 'user' },
						{ text: 'Crée le', value: 'created_at' },
						{ text: 'Mis à jour le', value: 'updated_at' },
					],"per-page":50}})],1),_c('v-tab-item',[_c('ViewAdmin',{attrs:{"item-type":"finitions","headers":[
						{ text: 'Action', value: 'action' },
						{ text: 'Activé/Désactivé', value: 'enabled' },
						{ text: 'Id', align: 'start', value: 'id' },
						{ text: 'Libellé', value: 'name' },
						{ text: 'Libellé Anglais', value: 'en_name' },
						{
							text: 'Hauteur minimum intérieure',
							value: 'min_height_interieur',
						},
						{
							text: 'Hauteur maximum intérieure',
							value: 'max_height_interieur',
						},
						{
							text: 'Hauteur minimum couverture',
							value: 'min_height_couverture',
						},
						{
							text: 'Hauteur maximum couverture',
							value: 'max_height_couverture',
						},
						{
							text: 'Largeur minimum intérieure',
							value: 'min_width_interieur',
						},
						{
							text: 'Largeur maximum intérieure',
							value: 'max_width_interieur',
						},
						{
							text: 'Largeur minimum couverture',
							value: 'min_width_couverture',
						},
						{
							text: 'Largeur maximum couverture',
							value: 'max_width_couverture',
						},
						{ text: 'Nombre de pages minimum', value: 'min_page_count' },
						{ text: 'Nombre de pages maximum', value: 'max_page_count' },
						{ text: 'Quantité minimum', value: 'min_quantity' },
						{ text: 'Quantité maximum', value: 'max_quantity' },
						{ text: 'Epaisseur minimum', value: 'min_epaisseur' },
						{ text: 'Epaisseur maximum', value: 'max_epaisseur' },
						{ text: 'Bords perdus minimum', value: 'min_bords_perdus' },
						{ text: 'Bords perdus maximum', value: 'max_bords_perdus' },
						{
							text: 'Pourcentage minimum bords perdus',
							value: 'max_pourcentage_bords_perdus',
						},
						{ text: 'Crée le', value: 'created_at' },
						{ text: 'Mis à jour le', value: 'updated_at' },
					],"per-page":50}})],1),_c('v-tab-item',[_c('ViewAdmin',{attrs:{"item-type":"impressions","headers":[
						{ text: 'Action', value: 'action' },
						{ text: 'Activé/Désactivé', value: 'enabled' },
						{ text: 'Id', align: 'start', value: 'id' },
						{ text: 'Libellé', value: 'name' },
						{ text: 'Libellé Anglais', value: 'en_name' },
						{ text: 'Nombre de pages minimum', value: 'min_page_count' },
						{ text: 'Nombre de pages maximum', value: 'max_page_count' },
						{ text: 'Crée le', value: 'created_at' },
						{ text: 'Mis à jour le', value: 'updated_at' },
					],"per-page":50}})],1),_c('v-tab-item',[_c('ViewAdmin',{attrs:{"item-type":"pelliculages","headers":[
						{ text: 'Action', value: 'action' },
						{ text: 'Activé/Désactivé', value: 'enabled' },
						{ text: 'Id', align: 'start', value: 'id' },
						{ text: 'Libellé', value: 'name' },
						{ text: 'Libellé Anglais', value: 'en_name' },
						{ text: 'Crée le', value: 'created_at' },
						{ text: 'Mis à jour le', value: 'updated_at' },
					],"per-page":50}})],1),_c('v-tab-item',[_c('ViewAdmin',{attrs:{"item-type":"papiersinterieur","headers":[
						{ text: 'Action', value: 'action' },
						{ text: 'Activé/Désactivé', value: 'enabled' },
						{ text: 'Papier Création', value: 'is_creation' },
						{ text: 'Id', align: 'start', value: 'id' },
						{ text: 'Libellé', value: 'name' },
						{ text: 'Libellé Anglais', value: 'en_name' },
						{ text: 'Libellé Fichier', value: 'file_name' },
						{ text: 'Poids', value: 'weight' },
						{ text: 'Epaisseur', value: 'epaisseur' },
						{ text: 'Prix', value: 'price' },
						{ text: 'Impression', value: 'impression.name' },
						{ text: 'Min Exemplaires', value: 'min_exemplaires' },
						{
							text: 'Min Exemplaires Couleur',
							value: 'min_exemplaires_couleur',
						},
						{
							text: 'Max Exemplaires',
							value: 'max_exemplaires',
						},
						{
							text: 'Max Exemplaires Couleur',
							value: 'max_exemplaires_couleur',
						},
						{ text: 'Crée le', value: 'created_at' },
						{ text: 'Mis à jour le', value: 'updated_at' },
					],"per-page":50}})],1),_c('v-tab-item',[_c('ViewAdmin',{attrs:{"item-type":"papierscouverture","headers":[
						{ text: 'Action', value: 'action' },
						{ text: 'Activé/Désactivé', value: 'enabled' },
						{ text: 'Papier Création', value: 'is_creation' },
						{ text: 'Id', align: 'start', value: 'id' },
						{ text: 'Libellé', value: 'name' },
						{ text: 'Libellé Anglais', value: 'en_name' },
						{ text: 'Libellé Fichier', value: 'file_name' },
						{ text: 'Poids', value: 'weight' },
						{ text: 'Epaisseur', value: 'epaisseur' },
						{ text: 'Prix', value: 'price' },
						{ text: 'Impression', value: 'impression.name' },
						{ text: 'Min Exemplaires', value: 'min_exemplaires' },
						{
							text: 'Min Exemplaires Couleur',
							value: 'min_exemplaires_couleur',
						},
						{
							text: 'Max Exemplaires',
							value: 'max_exemplaires',
						},
						{
							text: 'Max Exemplaires Couleur',
							value: 'max_exemplaires_couleur',
						},
						{ text: 'Crée le', value: 'created_at' },
						{ text: 'Mis à jour le', value: 'updated_at' },
					],"per-page":50}})],1),_c('v-tab-item',[_c('ViewAdmin',{attrs:{"item-type":"etats","headers":[
						{ text: 'Action', value: 'action' },
						{ text: 'Activé/Désactivé', value: 'enabled' },
						{ text: 'Id', align: 'start', value: 'id' },
						{ text: 'Libellé', value: 'name' },
						{ text: 'Libellé Anglais', value: 'en_name' },
						{ text: 'Crée le', value: 'created_at' },
						{ text: 'Mis à jour le', value: 'updated_at' },
					],"per-page":50}})],1),_c('v-tab-item',[_c('ViewAdmin',{attrs:{"item-type":"etatscommande","headers":[
						{ text: 'Action', value: 'action' },
						{ text: 'Activé/Désactivé', value: 'enabled' },
						{ text: 'Id', align: 'start', value: 'id' },
						{ text: 'Libellé', value: 'name' },
						{ text: 'Libellé Anglais', value: 'en_name' },
						{ text: 'Crée le', value: 'created_at' },
						{ text: 'Mis à jour le', value: 'updated_at' },
					],"per-page":50}})],1),_c('v-tab-item',[_c('ViewAdmin',{attrs:{"item-type":"etatslignecommande","headers":[
						{ text: 'Action', value: 'action' },
						{ text: 'Activé/Désactivé', value: 'enabled' },
						{ text: 'Id', align: 'start', value: 'id' },
						{ text: 'Libellé', value: 'name' },
						{ text: 'Libellé Anglais', value: 'en_name' },
						{ text: 'Crée le', value: 'created_at' },
						{ text: 'Mis à jour le', value: 'updated_at' },
					]}})],1),_c('v-tab-item',[_c('ViewAdmin',{attrs:{"item-type":"acheves","headers":[
						{ text: 'Action', value: 'action' },
						{ text: 'Activé/Désactivé', value: 'enabled' },
						{ text: 'Id', align: 'start', value: 'id' },
						{ text: 'Libellé', value: 'name' },
						{ text: 'Libellé Anglais', value: 'en_name' },
						{ text: 'Crée le', value: 'created_at' },
						{ text: 'Mis à jour le', value: 'updated_at' },
					]}})],1),_c('v-tab-item',[_c('ViewAdmin',{attrs:{"item-type":"categories","headers":[
						{ text: 'Action', value: 'action' },
						{ text: 'Activé/Désactivé', value: 'enabled' },
						{ text: 'Id', align: 'start', value: 'id' },
						{ text: 'Libellé', value: 'name' },
						{ text: 'Libellé Anglais', value: 'en_name' },
						{ text: 'TVA', value: 'tva' },
						{ text: 'Crée le', value: 'created_at' },
						{ text: 'Mis à jour le', value: 'updated_at' },
					]}})],1),_c('v-tab-item',[_c('ViewAdmin',{attrs:{"item-type":"countries","headers":[
						{ text: 'Action', value: 'action' },
						{ text: 'Activé/Désactivé', value: 'enabled' },
						{ text: 'Id', align: 'start', value: 'id' },
						{ text: 'Libellé', value: 'name' },
						{ text: 'Libellé Anglais', value: 'en_name' },
						{ text: 'ISO', value: 'iso' },
						{ text: 'Export', value: 'is_export' },
						{ text: 'Crée le', value: 'created_at' },
						{ text: 'Mis à jour le', value: 'updated_at' },
					]}})],1),_c('v-tab-item',[_c('ViewAdmin',{attrs:{"item-type":"pending_cart","headers":[
						{ text: 'Action', value: 'action' },
						{ text: 'Activé/Désactivé', value: 'enabled' },
						{ text: 'Id', align: 'start', value: 'id' },
						{ text: 'Uid', align: 'start', value: 'uid' },
						{ text: 'Etat', value: 'f1.name' },
						{ text: 'Prix Fabrication', value: 'prix_fabrication' },
						{ text: 'Prix Livraison', value: 'prix_livraison' },
						{ text: 'Prix Emballage', value: 'prix_emballage' },
						{ text: 'Prix HT', value: 'prix_ht' },
						{ text: 'Prix TTC', value: 'prix_ttc' },
						{ text: 'Crée le', value: 'created_at' },
						{ text: 'Mis à jour le', value: 'updated_at' },
						{ text: 'Utilisateur', value: 'user' },
						{ text: 'Lignes de commande', value: 'lignes_commande' },
					],"per-page":50}})],1),_c('v-tab-item',[_c('ViewAdminServerSide',{attrs:{"item-type":"simulation_logs","headers":[
						{ text: 'Id', align: 'start', value: 'id' },
						{ text: 'Action', value: 'action' },

						{ text: 'Sauvegardée', value: 'enabled' },
						{ text: 'Utilisateur', value: 'user' },
						{ text: 'Données', value: 'data' },
						{ text: 'Date', value: 'datetime' },
					],"per-page":500,"sort-by":"datetime"}})],1),_c('v-tab-item',[_c('ViewAdminServerSide',{attrs:{"item-type":"logs","headers":[
						{ text: 'Id', align: 'start', value: 'id' },
						{ text: 'Ip', value: 'ip' },
						{ text: 'Méthode', value: 'method' },
						{ text: 'Route', value: 'route' },
						{ text: 'Utilisateur', value: 'user' },
						{ text: 'Date', value: 'datetime' },
					],"per-page":500,"sort-by":"datetime"}})],1),_c('v-tab-item',[_c('ViewAdminServerSide',{attrs:{"item-type":"otp_logs","headers":[
						{ text: 'Id', align: 'start', value: 'id' },
						{ text: 'Code', value: 'code' },
						{ text: 'Utilsateur', value: 'user' },
						{ text: 'Utilisé', value: 'used' },
						{ text: 'Crée le', value: 'created_at' },
					],"per-page":50,"sort-by":"created_at"}})],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }