module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "order_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commander le produit"])},
        "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter au panier"])},
        "added_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit ajouté au panier"])},
        "quantity_min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La commande devra contenir au moins ", _interpolate(_list(0)), " exemplaires"])},
        "quantity_min_pique": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Quantité minimum supérieure à ", _interpolate(_list(0))])},
        "quantity_max": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Quantité inférieure ou égal à ", _interpolate(_list(0))])},
        "line_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne modifiée"])}
      },
      "en": {
        "order_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order product"])},
        "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to cart"])},
        "added_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product added to cart"])},
        "quantity_min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The order must contain at least ", _interpolate(_list(0)), " exemplars"])},
        "quantity_min_pique": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Quantity above ", _interpolate(_list(0))])},
        "quantity_max": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Quantity equal or less than ", _interpolate(_list(0))])},
        "line_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line edited"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"order_product":"Commander le produit","add_to_cart":"Ajouter au panier","added_to_cart":"Produit ajouté au panier","quantity_min":"La commande devra contenir au moins {0} exemplaires","quantity_min_pique":"Quantité minimum supérieure à {0}","quantity_max":"Quantité inférieure ou égal à {0}","line_edited":"Ligne modifiée"},"en":{"order_product":"Order product","add_to_cart":"Add to cart","added_to_cart":"Product added to cart","quantity_min":"The order must contain at least {0} exemplars","quantity_min_pique":"Quantity above {0}","quantity_max":"Quantity equal or less than {0}","line_edited":"Line edited"}}')
  delete Component.options._Ctor
  
}
