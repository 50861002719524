module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer le compte"])},
        "user_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte modifié"])},
        "username_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur déjà pris"])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit account"])},
        "user_edited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account edited"])},
        "username_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username already taken"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"title":"Editer le compte","user_edited":"Compte modifié","username_exists":"Nom d\u0027utilisateur déjà pris"},"en":{"title":"Edit account","user_edited":"Account edited","username_exists":"Username already taken"}}')
  delete Component.options._Ctor
  
}
