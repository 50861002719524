<template>
	<v-card shaped elevation="24">
		<v-card-text>
			<v-list>
				<v-list-item>
					<v-list-item-content>
						<strong>{{ address.address_full }}</strong>
						<v-divider class="my-4"></v-divider>
						<div class="overflow-y-auto" style="height: 200px">
							<div>
								<strong>{{ $t('address.contact') }}</strong
								>: {{ address.contact }}
							</div>
							<div>
								<strong>{{ $t('address.raison_sociale') }}</strong
								>:
								<span v-if="address.raison_sociale"> {{ address.raison_sociale }}</span>
								<span v-else class="subtitle-2 grey--text font-italic">
									{{ $t('address.no_raison_sociale') }}
								</span>
							</div>
							<div>
								<strong>{{ $t('address.address') }}</strong> :
								{{ address.address }}
							</div>
							<div v-if="address.complement">
								<strong>{{ $t('address.complement') }}</strong> :
								{{ address.complement }}
							</div>
							<div>
								<strong>{{ $t('address.postal_code') }}</strong> :
								{{ address.code_postal }}
							</div>
							<div>
								<strong>{{ $t('address.city') }}</strong> :
								{{ address.city }}
							</div>
							<div>
								<strong>{{ $t('address.country') }}</strong> :
								{{ $i18n.locale === 'fr' ? address.country.name : address.country.en_name }}
							</div>
							<div v-if="address.phone_mobile">
								<strong>{{ $t('address.phone.mobile') }}</strong> :
								{{ address.phone_mobile }}
							</div>
							<div v-if="address.phone_fix">
								<strong>{{ $t('address.phone.fix') }}</strong> :
								{{ address.phone_fix }}
							</div>
							<div>
								<strong>{{ $t('global.email') }}</strong> :
								{{ address.email }}
							</div>
							<div>
								<strong>{{ $t('address.type') }}</strong> :
								{{ $i18n.locale === 'fr' ? address.address_type.name : address.address_type.en_name }}
							</div>
							<strong v-if="address.comment">{{ $t('address.comment') }} :</strong>
							<div>
								<i>{{ address.comment }}</i>
							</div>
						</div>
						<i class="text-caption text--secondary">
							{{ $t('global.created_at') }} :
							{{ new Date(new Date(address.created_at).toLocaleString('en-US')).toLocaleString() }}
						</i>
						<i class="text-caption text--secondary">
							{{ $t('global.updated_at') }} :
							{{ new Date(new Date(address.updated_at).toLocaleString('en-US')).toLocaleString() }}
						</i>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-card-text>
		<v-card-actions>
			<div class="d-flex gap-2 ml-auto">
				<v-btn icon large color="orange" @click="pinAddress(address.id, address.pinned)">
					<v-icon v-if="address.pinned">mdi-pin</v-icon>
					<v-icon v-else>mdi-pin-outline</v-icon>
				</v-btn>
				<v-btn icon large color="orange" @click="favoriteAddress(address.id)">
					<v-icon v-if="address.favorite">mdi-star</v-icon>
					<v-icon v-else>mdi-star-outline</v-icon>
				</v-btn>
				<AddressForm :addr="address" class="ma-0" />
				<v-dialog v-model="deleteDialog" max-width="500px" transition="dialog-transition">
					<v-card shaped elevation="24">
						<v-card-title class="text-h5">
							{{ $t('are_you_sure') }}
						</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn class="ml-auto border-shaped yellow--bg" depressed @click="deleteAddress(address.id)">
								<v-icon>mdi-check</v-icon>
							</v-btn>
							<v-btn class="border-shaped" color="red" depressed @click="deleteDialog = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-btn color="red" class="border-shaped" depressed @click="deleteDialog = true">
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</div>
		</v-card-actions>
	</v-card>
</template>

<script>
	import axios from 'axios';
	import { EventBus } from '@/utils';
	import AddressForm from '@/components/address/AddressForm';

	export default {
		name: 'AddressDetail',
		props: ['address'],
		components: {
			AddressForm,
		},
		data() {
			return {
				deleteDialog: false,
			};
		},
		methods: {
			deleteAddress(id) {
				axios
					.delete(process.env.VUE_APP_API_URL + '/address/' + id, {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then(() => {
						EventBus.$emit('refreshAddresses');
						EventBus.$emit('notif', 'Adresse supprimée', 'success');
					})
					.catch(() => {
						EventBus.$emit('notif', 'Une erreur est survenue', 'error');
					});
			},
			pinAddress(id, pinned) {
				if (!pinned) {
					axios
						.patch(
							process.env.VUE_APP_API_URL + '/address/' + id,
							{},
							{
								headers: {
									Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
								},
							}
						)
						.then(() => {
							EventBus.$emit('refreshAddresses');
							EventBus.$emit('notif', 'Adresse mis à jour', 'success');
						})
						.catch(() => {
							EventBus.$emit('notif', 'Une erreur est survenue', 'error');
						});
				}
			},
			favoriteAddress(id) {
				axios
					.patch(
						process.env.VUE_APP_API_URL + '/address/' + id,
						{
							favorite: true,
						},
						{
							headers: {
								Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
							},
						}
					)
					.then(() => {
						EventBus.$emit('refreshAddresses');
						EventBus.$emit('notif', 'Adresse mis à jour', 'success');
					})
					.catch(() => {
						EventBus.$emit('notif', 'Une erreur est survenue', 'error');
					});
			},
		},
	};
</script>

<i18n lang="json">
{
	"fr": {
		"are_you_sure": "Etes-vous certain de vouloir supprimer cette adresse ?"
	},
	"en": {
		"are_you_sure": "Are you sure you want to delete this address ?"
	}
}
</i18n>
