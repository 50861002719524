module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
        "mail_will_be_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un email vous sera envoyé afin de réinitialiser votre mot de passe."])},
        "mail_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un email vous a été envoyé."])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite. l'email ne correspond peut-être pas à un compte."])}
      },
      "en": {
        "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password ?"])},
        "mail_will_be_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email will be sent to reset your password."])},
        "mail_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email has been sent."])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred. The email may not be associated with an account."])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"forgot_password":"Mot de passe oublié ?","mail_will_be_sent":"Un email vous sera envoyé afin de réinitialiser votre mot de passe.","mail_sent":"Un email vous a été envoyé.","error":"Une erreur s\u0027est produite. l\u0027email ne correspond peut-être pas à un compte."},"en":{"forgot_password":"Forgot password ?","mail_will_be_sent":"An email will be sent to reset your password.","mail_sent":"An email has been sent.","error":"An error has occurred. The email may not be associated with an account."}}')
  delete Component.options._Ctor
  
}
