module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulation Tarifaire"])},
        "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disponible"])},
        "go_to_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer à la création de produit"])},
        "choose_impression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un mode d'impression"])},
        "quantity_min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Quantité supérieure à ", _interpolate(_list(0))])},
        "quantity_min_pique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité minimum supérieure à 50"])},
        "quantity_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité inférieure ou égal à 2000"])},
        "page_count_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pages supérieur à 0"])},
        "page_count_min_pique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pages supérieur à 8"])},
        "page_count_max_pique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pages inférieure à 76 (76 + 4 (couverture))"])},
        "page_count_odd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pages positif ou divisible par 2"])},
        "page_count_by_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pages positif ou divisible par 4"])},
        "calc_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simuler"])},
        "width_limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La largeur doit être comprise entre ", _interpolate(_list(0)), " et ", _interpolate(_list(1)), " mm"])},
        "height_limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La hauteur doit être comprise entre ", _interpolate(_list(0)), " et ", _interpolate(_list(1)), " mm"])},
        "enable_couverture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer/Désactiver la couverture"])},
        "papier_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de pelliculage pour un papier de création"])},
        "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider ce produit"])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing Simulation"])},
        "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unavailable"])},
        "go_to_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to product create form"])},
        "choose_impression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a printing mode"])},
        "quantity_min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Quantity above ", _interpolate(_list(0))])},
        "quantity_min_pique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity above 50"])},
        "quantity_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity equal or less than 2000"])},
        "page_count_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of pages above 0"])},
        "page_count_min_pique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of pages above 8"])},
        "page_count_max_pique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of pages below 76 (76 + 4 (cover))"])},
        "page_count_odd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of pages positive or divisible by 2"])},
        "page_count_by_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of pages positive or divisible by 4"])},
        "calc_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulate"])},
        "width_limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The width must be between ", _interpolate(_list(0)), " and ", _interpolate(_list(1)), " mm"])},
        "height_limit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The height must be between ", _interpolate(_list(0)), " and ", _interpolate(_list(1)), " mm"])},
        "enable_couverture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable/Disable cover"])},
        "papier_creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pelliculage for a creation paper"])},
        "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate this product"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"title":"Simulation Tarifaire","unavailable":"Non disponible","go_to_creation":"Passer à la création de produit","choose_impression":"Choisissez un mode d\u0027impression","quantity_min":"Quantité supérieure à {0}","quantity_min_pique":"Quantité minimum supérieure à 50","quantity_max":"Quantité inférieure ou égal à 2000","page_count_min":"Nombre de pages supérieur à 0","page_count_min_pique":"Nombre de pages supérieur à 8","page_count_max_pique":"Nombre de pages inférieure à 76 (76 + 4 (couverture))","page_count_odd":"Nombre de pages positif ou divisible par 2","page_count_by_4":"Nombre de pages positif ou divisible par 4","calc_price":"Simuler","width_limit":"La largeur doit être comprise entre {0} et {1} mm","height_limit":"La hauteur doit être comprise entre {0} et {1} mm","enable_couverture":"Activer/Désactiver la couverture","papier_creation":"Pas de pelliculage pour un papier de création","validate":"Valider ce produit"},"en":{"title":"Pricing Simulation","unavailable":"Unavailable","go_to_creation":"Go to product create form","choose_impression":"Choose a printing mode","quantity_min":"Quantity above {0}","quantity_min_pique":"Quantity above 50","quantity_max":"Quantity equal or less than 2000","page_count_min":"Number of pages above 0","page_count_min_pique":"Number of pages above 8","page_count_max_pique":"Number of pages below 76 (76 + 4 (cover))","page_count_odd":"Number of pages positive or divisible by 2","page_count_by_4":"Number of pages positive or divisible by 4","calc_price":"Simulate","width_limit":"The width must be between {0} and {1} mm","height_limit":"The height must be between {0} and {1} mm","enable_couverture":"Enable/Disable cover","papier_creation":"No pelliculage for a creation paper","validate":"Validate this product"}}')
  delete Component.options._Ctor
  
}
