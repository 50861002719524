module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "are_you_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous certain de vouloir supprimer ce produit ?"])},
        "product_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit supprimé"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])}
      },
      "en": {
        "are_you_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this product ?"])},
        "product_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product deleted"])},
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"are_you_sure":"Etes-vous certain de vouloir supprimer ce produit ?","product_deleted":"Produit supprimé","delete":"Supprimer"},"en":{"are_you_sure":"Are you sure you want to delete this product ?","product_deleted":"Product deleted","delete":"Delete"}}')
  delete Component.options._Ctor
  
}
