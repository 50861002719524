module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous Contacter"])},
        "object": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objet"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du projet et Informations supplémentaires"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu"])},
        "submit-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
        "our_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre Adresse"])},
        "register_demand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande d'inscription"])},
        "sended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail envoyé"])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
        "object": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Description and Additional Information"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
        "submit-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
        "our_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Address"])},
        "register_demand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Request"])},
        "sended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail sent"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"title":"Nous Contacter","object":"Objet","email":"Email","phone":"Téléphone","description":"Description du projet et Informations supplémentaires","content":"Contenu","submit-contact":"Envoyer","our_address":"Notre Adresse","register_demand":"Demande d\u0027inscription","sended":"Mail envoyé"},"en":{"title":"Contact Us","object":"Subject","email":"Email","phone":"Phone","description":"Project Description and Additional Information","content":"Content","submit-contact":"Send","our_address":"Our Address","register_demand":"Registration Request","sended":"Mail sent"}}')
  delete Component.options._Ctor
  
}
