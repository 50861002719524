module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulation Tarifaire"])},
        "cannot_calc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de calculer le prix"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique"])},
        "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothèque"])},
        "no_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun produit"])},
        "add_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un produit"])},
        "add_order_point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un point de livraison"])},
        "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panier"])},
        "delivery_points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points de livraison"])},
        "no_delivery_points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun point de livraison"])},
        "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculer le prix"])},
        "line_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulation enregistrée"])},
        "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disponible"])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing Simulation"])},
        "cannot_calc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot calculate price"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
        "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
        "no_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No product"])},
        "add_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a product"])},
        "add_order_point": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an order point"])},
        "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart"])},
        "delivery_points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery points"])},
        "no_delivery_points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No delivery points"])},
        "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate the price"])},
        "line_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulation saved"])},
        "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unavailable"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"title":"Simulation Tarifaire","cannot_calc":"Impossible de calculer le prix","history":"Historique","library":"Bibliothèque","no_product":"Aucun produit","add_product":"Ajouter un produit","add_order_point":"Ajouter un point de livraison","cart":"Panier","delivery_points":"Points de livraison","no_delivery_points":"Aucun point de livraison","calculate":"Calculer le prix","line_saved":"Simulation enregistrée","unavailable":"Non disponible"},"en":{"title":"Pricing Simulation","cannot_calc":"Cannot calculate price","history":"History","library":"Library","no_product":"No product","add_product":"Add a product","add_order_point":"Add an order point","cart":"Cart","delivery_points":"Delivery points","no_delivery_points":"No delivery points","calculate":"Calculate the price","line_saved":"Simulation saved","unavailable":"Unavailable"}}')
  delete Component.options._Ctor
  
}
