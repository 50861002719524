module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "are_you_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous certain de vouloir supprimer cette adresse ?"])}
      },
      "en": {
        "are_you_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this address ?"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"are_you_sure":"Etes-vous certain de vouloir supprimer cette adresse ?"},"en":{"are_you_sure":"Are you sure you want to delete this address ?"}}')
  delete Component.options._Ctor
  
}
