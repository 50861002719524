module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panier"])},
        "empty_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panier Vide"])},
        "cart_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la récupération du panier"])},
        "are_you_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir passer commande ?"])},
        "cart_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panier supprimé"])},
        "cart_validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panier validé"])},
        "product_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit supprimé du panier"])},
        "emb_type_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type Emballage modifié"])},
        "cannot_delete_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de supprimer le produit du panier, supprimez le panier ou modifier les quantités"])},
        "caisse_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveauté : pour les adresses diffuseur au-dessus de 499 exemplaires, un tarif préférentiel peut s'appliquer avec un emballage mis sous film"])}
      },
      "en": {
        "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart"])},
        "empty_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty Cart"])},
        "cart_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error retrieving cart"])},
        "are_you_sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to proceed ?"])},
        "cart_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart deleted"])},
        "cart_validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart validated"])},
        "product_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product deleted from cart"])},
        "emb_type_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packaging type changed"])},
        "cannot_delete_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot delete product from cart, delete cart or change quantities"])},
        "caisse_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New: for distributor addresses above 499 copies, a preferential rate may apply for shrink-wrapped packaging."])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"cart":"Panier","empty_cart":"Panier Vide","cart_error":"Erreur lors de la récupération du panier","are_you_sure":"Êtes-vous sûr de vouloir passer commande ?","cart_deleted":"Panier supprimé","cart_validated":"Panier validé","product_deleted":"Produit supprimé du panier","emb_type_changed":"Type Emballage modifié","cannot_delete_product":"Impossible de supprimer le produit du panier, supprimez le panier ou modifier les quantités","caisse_warning":"Nouveauté : pour les adresses diffuseur au-dessus de 499 exemplaires, un tarif préférentiel peut s\u0027appliquer avec un emballage mis sous film"},"en":{"cart":"Cart","empty_cart":"Empty Cart","cart_error":"Error retrieving cart","are_you_sure":"Are you sure you want to proceed ?","cart_deleted":"Cart deleted","cart_validated":"Cart validated","product_deleted":"Product deleted from cart","emb_type_changed":"Packaging type changed","cannot_delete_product":"Cannot delete product from cart, delete cart or change quantities","caisse_warning":"New: for distributor addresses above 499 copies, a preferential rate may apply for shrink-wrapped packaging."}}')
  delete Component.options._Ctor
  
}
