var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mb-3 d-flex align-center"},[_c('v-icon',{staticClass:"orange--text"},[_vm._v("mdi-pin")]),_vm._v(": "+_vm._s(_vm.$t('address.billing'))+" ")],1),_c('div',{staticClass:"mb-3 d-flex align-center"},[_c('v-icon',{staticClass:"orange--text"},[_vm._v("mdi-star")]),_vm._v(": "+_vm._s(_vm.$t('address.favorite'))+" ")],1),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{staticClass:"my-10",attrs:{"size":150,"color":"yellow","indeterminate":""}},[_vm._v(" "+_vm._s(_vm.$t('loading'))+" ")])],1):_c('v-data-iterator',{attrs:{"items":_vm.addresses,"hide-default-footer":"","items-per-page":-1,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"search":_vm.search},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-md-flex"},[_c('v-select',{staticClass:"border-shaped mr-2",attrs:{"items":[
						{ text: _vm.$t('address.favorite'), value: 'favorite' },
						{ text: _vm.$t('address.billing'), value: 'pinned' },
						{ text: _vm.$t('address.address'), value: 'address' },
						{ text: _vm.$t('address.city'), value: 'city' },
						{ text: _vm.$t('address.postal_code'), value: 'code_postal' },
						{ text: _vm.$t('address.country'), value: 'country.iso' },
						{ text: _vm.$t('global.phone'), value: 'phone' },
						{ text: _vm.$t('global.email'), value: 'email' },
						{ text: _vm.$t('global.created_at'), value: 'created_at' },
					],"label":_vm.$t('sort'),"outlined":""},model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}}),_c('v-btn-toggle',{staticClass:"mt-n4 mt-md-0",attrs:{"mandatory":""},model:{value:(_vm.sortDesc),callback:function ($$v) {_vm.sortDesc=$$v},expression:"sortDesc"}},[_c('v-btn',{staticClass:"border-shaped",attrs:{"x-large":"","value":false}},[_c('v-icon',[_vm._v("mdi-arrow-up")])],1),_c('v-btn',{staticClass:"border-shaped mx-2",attrs:{"x-large":"","value":true}},[_c('v-icon',[_vm._v("mdi-arrow-down")])],1)],1),_c('v-text-field',{staticClass:"border-shaped mt-3 mt-md-0",attrs:{"outlined":"","label":_vm.$t('form.search'),"prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"default",fn:function(props){return [_c('v-row',_vm._l((props.items),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"12","sm":"6","md":"6","lg":"4","xl":"3"}},[_c('v-lazy',{attrs:{"transition":"scale-transition"}},[_c('AddressDetail',{attrs:{"address":item}})],1)],1)}),1)]}},{key:"no-data",fn:function(){return [_c('i',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('no_data')))])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }