module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulation Tarifaire"])},
        "order_points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points de livraison"])},
        "monopoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un seul point de livraison"])},
        "multipoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plusieurs points de livraison"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos simulations passées"])},
        "no_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun historique"])},
        "simulation_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'historique est stocké sur nos serveurs cependant la librairie est sauvegardée dans votre navigateur"])},
        "line_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne supprimée"])},
        "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produits"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing Simulation"])},
        "order_points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery points"])},
        "monopoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One delivery point"])},
        "multipoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple delivery points"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your past simulations"])},
        "no_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No history"])},
        "simulation_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The history is stored on our servers however the library is saved in your browser"])},
        "line_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line deleted"])},
        "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"title":"Simulation Tarifaire","order_points":"Points de livraison","monopoint":"Un seul point de livraison","multipoint":"Plusieurs points de livraison","history":"Vos simulations passées","no_history":"Aucun historique","simulation_info":"L\u0027historique est stocké sur nos serveurs cependant la librairie est sauvegardée dans votre navigateur","line_deleted":"Ligne supprimée","products":"Produits","address":"Adresse"},"en":{"title":"Pricing Simulation","order_points":"Delivery points","monopoint":"One delivery point","multipoint":"Multiple delivery points","history":"Your past simulations","no_history":"No history","simulation_info":"The history is stored on our servers however the library is saved in your browser","line_deleted":"Line deleted","products":"Products","address":"Address"}}')
  delete Component.options._Ctor
  
}
