<template>
	<v-dialog v-model="dialog" max-width="900px" :persistent="loading">
		<template #activator="{ on, attrs }">
			<v-btn class="border-shaped ml-2 my-1 yellow--bg" v-bind="attrs" depressed v-on="on">
				<v-icon>mdi-pencil</v-icon>
			</v-btn>
		</template>
		<v-form ref="formEditOrder" v-model="valid" @submit.prevent="editLine">
			<v-card>
				<v-card-title class="text-h4 text-lg-h3 font-weight-bold">
					{{ $t('order_product') }}
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<v-alert v-if="this.quantity < this.minQuantity" type="warning" outlined shaped>
						{{ $t('quantity_min', [this.minQuantity]) }}
					</v-alert>
					<v-text-field
						v-model="quantity"
						type="number"
						:label="$t('order.line.quantity')"
						calc-icon=""
						class="border-shaped"
						outlined
						:use-grouping="false"
						:rules="quantityRules"
						:min="1"
						:max="maxQuantity"
					></v-text-field>
					<v-row>
						<v-col cols="12" md="8">
							<v-autocomplete
								v-model="address"
								:label="$t('address.address')"
								class="border-shaped"
								outlined
								clearable
								:items="addresses"
								:rules="addressRules"
								:item-text="
									(item) =>
										(item.pinned ? '📌' : '') +
											' ' +
											(item.favorite ? '⭐' : '') +
											' ' +
											item.address_full +
											' - ' +
											$i18n.locale ===
										'fr'
											? item.country.name
											: item.country.en_name +
											  ' ' +
											  (item.complement ? item.complement : '') +
											  ' ' +
											  (item.contact ? item.contact : '') +
											  ' ' +
											  (item.raison_sociale ? item.raison_sociale : '') +
											  ' ' +
											  (item.email ? item.email : '')
								"
								return-object
							>
								<template #item="{ item }">
									<v-list-item-content class="text-caption">
										<strong>
											{{ item.address_full }}
											{{ item.favorite ? '⭐' : '' }}
											{{ item.pinned ? '📌' : '' }}
										</strong>
										<div class="overflow-y-auto">
											<div>
												<strong>{{ $t('address.contact') }}</strong
												>: {{ item.contact }}
											</div>
											<div>
												<strong>{{ $t('address.raison_sociale') }}</strong
												>:
												<span v-if="item.raison_sociale"> {{ item.raison_sociale }}</span>
												<span v-else class="grey--text font-italic"> Pas de raison sociale </span>
											</div>
											<div>
												<strong>{{ $t('address.address') }}</strong> :
												{{ item.address }}
											</div>
											<div v-if="item.complement">
												<strong>{{ $t('address.complement') }}</strong> :
												{{ item.complement }}
											</div>
											<div>
												<strong>{{ $t('address.postal_code') }}</strong> :
												{{ item.code_postal }}
											</div>
											<div>
												<strong>{{ $t('address.city') }}</strong> :
												{{ item.city }}
											</div>
											<div>
												<strong>{{ $t('address.country') }}</strong> :
												{{ $i18n.locale === 'fr' ? item.country.name : item.country.en_name }}
											</div>
											<div v-if="item.phone_mobile">
												<strong>{{ $t('address.phone.mobile') }}</strong> :
												{{ item.phone_mobile }}
											</div>
											<div v-if="item.phone_fix">
												<strong>{{ $t('address.phone.fix') }}</strong> :
												{{ item.phone_fix }}
											</div>
											<div>
												<strong>{{ $t('global.email') }}</strong> :
												{{ item.email }}
											</div>
											<div>
												<strong>{{ $t('address.type') }}</strong> :
												{{ $i18n.locale === 'fr' ? item.address_type.name : item.address_type.en_name }}
											</div>
										</div>
									</v-list-item-content>
								</template>
							</v-autocomplete>
						</v-col>
						<v-col cols="12" md="4">
							<AddressForm class="mt-1" />
						</v-col>
					</v-row>
					<v-textarea v-model="comment" :label="$t('address.comment')" outlined counter></v-textarea>
				</v-card-text>
				<v-card-actions>
					<v-btn class="border-shaped yellow--bg ml-auto mb-3" type="submit" :loading="loading" elevation="0">
						{{ $t('form.submit') }}
					</v-btn>
				</v-card-actions>
			</v-card>
			<v-lazy transition="slide-y-transition">
				<v-card v-if="errors" class="mt-2 mr-2">
					<v-card-text>
						<v-list dense>
							<v-subheader>{{ $t('global.errors') }} :</v-subheader>
							<v-list-item v-for="(e, i) in errors" :key="i" class="red--text text-justify">
								<v-list-item-icon>
									<v-icon class="red--text">mdi-alert-circle</v-icon>
								</v-list-item-icon>
								<v-list-item-content>{{ e }}</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card-text>
				</v-card>
			</v-lazy>
		</v-form>
	</v-dialog>
</template>

<script lang="js">
	import AddressForm from '@/components/address/AddressForm';
	import axios from 'axios';
	import { EventBus } from '@/utils';

	export default {
		name: 'OrderProductEdit',
		components: { AddressForm },
		props: ['line'],
		data() {
			return {
				dialog: false,
				valid: false,
				loading: false,
				errors: null,
				quantity: this.line.quantity,
				address: this.line.address,
				comment: this.line.comment,
				addresses: [],
				quantityRules: [
					() => {
						// if (this.quantity < this.minQuantity) {
						// 	return this.$t('quantity_min', [this.minQuantity]);
						// }
						if (this.quantity > this.maxQuantity) {
							return this.$t('quantity_max', [this.maxQuantity]);
						}
						return true;
					},
				],
				addressRules: [(v) => v !== null || this.$t('form.required.address.address')],
			};
		},
		computed: {
			minQuantity() {
				return this.line.product.finition?.id === 2
					? this.line.product.finition?.min_quantity
					: this.line.product.impression?.id === 1
						? Math.max(
							this.line.product.papier_couverture?.papier.min_exemplaires,
							this.line.product.papier_interieur?.papier.min_exemplaires
						) || this.line.product.finition?.min_quantity
						: Math.max(
							this.line.product.papier_couverture?.papier.min_exemplaires_couleur,
							this.line.product.papier_interieur?.papier.min_exemplaires_couleur
						) || this.line.product.finition?.min_quantity;
			},
			maxQuantity() {
				return this.line.product.finition?.id === 2
					? this.line.product.finition?.max_quantity
					: this.line.product.impression?.id === 1
						? Math.min(
							this.line.product.papier_couverture?.papier.max_exemplaires,
							this.line.product.papier_interieur?.papier.max_exemplaires
						) || this.line.product.finition?.max_quantity
						: Math.min(
							this.line.product.papier_couverture?.papier.max_exemplaires_couleur,
							this.line.product.papier_interieur?.papier.max_exemplaires_couleur
						) || this.line.product.finition?.max_quantity;
			},
		},
		mounted() {
			this.getAddresses();
			EventBus.$on('refreshAddresses', () => {
				this.getAddresses();
			});
		},
		methods: {
			getAddresses() {
				this.loading = true;
				axios
					.get(process.env.VUE_APP_API_URL + '/address', {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
						},
					})
					.then((response) => {
						this.addresses = response.data.addresses.toSorted((a, b) => {
							return a.favorite === b.favorite ? 0 : a.favorite ? -1 : 1;
						});
					})
					.catch(() => { })
					.finally(() => {
						this.loading = false;
					});
			},
			editLine(e) {
				e.preventDefault();
				if (this.$refs.formEditOrder.validate()) {
					this.loading = true;
					this.errors = null;
					const data = {
						line_id: this.line.id,
						quantity: this.quantity,
						address_id: this.address.id,
						comment: this.comment,
					};
					axios
						.put(process.env.VUE_APP_API_URL + '/order/line', data, {
							headers: {
								Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
							},
						})
						.then(() => {
							EventBus.$emit('refreshProducts');
							EventBus.$emit('refreshPanier');
							this.dialog = false;
							EventBus.$emit('notif', this.$t('line_edited'), 'success');
						})
						.catch((e) => {
							this.errors = e.response.data.errors;
							EventBus.$emit('notif', e.response.data.message, 'error');
						})
						.finally(() => {
							this.loading = false;
						});
				}
			},
		},
	};
</script>

<i18n lang="json">
{
	"fr": {
		"order_product": "Commander le produit",
		"add_to_cart": "Ajouter au panier",
		"added_to_cart": "Produit ajouté au panier",
		"quantity_min": "La commande devra contenir au moins {0} exemplaires",
		"quantity_min_pique": "Quantité minimum supérieure à {0}",
		"quantity_max": "Quantité inférieure ou égal à {0}",
		"line_edited": "Ligne modifiée"
	},
	"en": {
		"order_product": "Order product",
		"add_to_cart": "Add to cart",
		"added_to_cart": "Product added to cart",
		"quantity_min": "The order must contain at least {0} exemplars",
		"quantity_min_pique": "Quantity above {0}",
		"quantity_max": "Quantity equal or less than {0}",
		"line_edited": "Line edited"
	}
}
</i18n>
