<template>
	<v-app id="inspire">
		<div class="z-40 utils-menu">
			<v-lazy transition="scale-transition">
				<Tutos v-if="$store.getters.isAuthenticated && $router.currentRoute.path !== '/help'" />
			</v-lazy>
			<v-menu offset-y top rounded="lg" transition="scroll-y-reverse-transition">
				<template #activator="{ on, attrs }">
					<v-btn small fab v-bind="attrs" depressed v-on="on">
						<CountryFlag :country="$i18n.locale" rounded class="mt-n2 mr-n2" style="transform: scale(0.4)" />
					</v-btn>
				</template>
				<v-list>
					<v-list-item
						v-for="(lang, index) in [
							{ name: 'Français', flag: 'fr', value: 'fr' },
							{ name: 'English', flag: 'gb', value: 'gb' },
						]"
						:key="index"
						@click="changeLocale(lang.value)"
					>
						<v-list-item-icon>
							<CountryFlag :country="lang.flag" rounded class="mt-n2" />
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>
								{{ lang.name }}
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-menu>
		</div>
		<v-navigation-drawer v-model="drawerL" app disable-resize-watcher temporary class="z-50">
			<div class="tw-flex tw-flex-col tw-p-4">
				<div class="tw-flex tw-items-center tw-gap-4 tw-mt-5 tw-font-black tw-text-3xl">Rapidbook</div>
				<div class="tw-w-full tw-h-4 tw-my-5 tw-bg-yellow-400 tw-rounded-tl-xl tw-rounded-br-xl"></div>
			</div>
			<v-list nav dense>
				<v-list-item v-for="item in itemsWithCondition" :key="item.title" :to="{ name: item.route }" exact link>
					<v-list-item-icon>
						<v-icon>{{ item.icon }}</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title>{{ item.title }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item class="tw-absolute tw-bottom-1">
					<div class="tw-flex tw-flex-col tw-gap-1">
						<div class="tw-text-xs" v-if="$store.getters.isAuthenticated && $store.getters.isChild">
							{{ $t('global.parent') }} :
							{{ $store.getters.getParentUsername }}
						</div>
						<div class="tw-text-xs" v-if="$store.getters.isAuthenticated">
							{{ $t('global.user') }} :
							{{ $store.getters.getUsername }}
						</div>
						<div class="tw-text-xs">Build version : v{{ version }}</div>
					</div>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>

		<v-navigation-drawer
			v-model="drawerR"
			app
			disable-resize-watcher
			fixed
			right
			temporary
			width="500"
			class="tw-max-h-screen tw-z-50"
		>
			<div class="tw-flex tw-flex-col tw-justify-between tw-p-4">
				<div>
					<div class="tw-flex tw-items-center tw-gap-4 tw-mt-5 tw-font-black tw-text-3xl tw-w-full">
						{{ $t('menu.cart') }}
						<v-spacer></v-spacer>
						<v-btn fab plain depressed @click="drawerR = false">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</div>
					<div class="tw-w-full tw-h-4 tw-my-5 tw-bg-yellow-400 tw-rounded-tl-xl tw-rounded-br-xl"></div>
				</div>
				<panier></panier>
			</div>
		</v-navigation-drawer>

		<v-app-bar app fixed color="white" elevation="0">
			<v-app-bar-nav-icon @click="drawerL = !drawerL"></v-app-bar-nav-icon>

			<v-toolbar-title>
				<div class="mt-1 d-flex ml-n15 align-center">
					<div class="">
						<router-link :to="{ name: 'Home' }">
							<v-img
								contain
								height="64"
								lazy-src="@/assets/img/Rapidbook_web.svg"
								src="@/assets/img/Rapidbook_web.svg"
								width="300"
							/>
						</router-link>
						<span class="text-caption font-italic grey--text" style="position: absolute; bottom: 0; left: 56px">
							{{ $t('global.reserved_for_pros') }}
						</span>
					</div>
					<span v-if="$vuetify.breakpoint.smAndUp" class="mr-1 ml-n11">By</span>
					<v-img
						v-if="$vuetify.breakpoint.smAndUp"
						contain
						height="50"
						lazy-src="@/assets/img/logo-corlet-2021.png"
						src="@/assets/img/logo-corlet-2021.png"
						width="50"
					/>
				</div>
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn v-if="$store.getters.isAuthenticated && $store.getters.isAdmin" :to="{ name: 'Admin' }" fab text>
				<v-icon>mdi-cog-outline</v-icon>
			</v-btn>
			<div v-if="count > 0 && $store.getters.isAuthenticated" class="tw-relative tw-w-6 tw-h-6">
				<span class="tw-absolute tw-h-full tw-w-full tw-bg-yellow-400 tw-rounded-full tw-animate-ping"></span>
				<span
					class="tw-flex tw-items-center tw-h-full tw-w-full tw-bg-yellow-400 tw-rounded-full tw-justify-center tw-text-xs"
				>
					{{ count }}
				</span>
			</div>
			<div>
				<v-btn
					v-if="$store.getters.isAuthenticated && !$store.getters.isChild"
					fab
					text
					@click="drawerR = !drawerR"
					:class="count > 0 ? 'swing' : ''"
				>
					<v-icon>mdi-cart-outline</v-icon>
				</v-btn>
			</div>
		</v-app-bar>

		<v-main>
			<transition name="fade" mode="out-in">
				<router-view></router-view>
			</transition>
		</v-main>
		<v-footer dark>
			<v-row justify="center" no-gutters>
				<v-btn :to="'contact'" class="tw-my-2 tw-mr-2 border-shaped" color="white" text>
					{{ $t('menu.contact') }}
				</v-btn>
				<v-btn :to="'mentions'" class="tw-my-2 border-shaped" color="white" text>
					{{ $t('menu.mentions') }}
				</v-btn>
				<v-btn :to="'help'" class="tw-my-2 border-shaped" color="white" text>
					{{ $t('menu.need_help') }}
				</v-btn>
				<v-col class="tw-py-4 tw-text-center" cols="12">
					Copyright © {{ new Date().getFullYear() }} All Rights Reserved by Rapidbook by Corlet
				</v-col>
			</v-row>
		</v-footer>

		<v-snackbar v-model="snackbar" :color="color" shaped bottom timeout="5000">
			<div class="tw-text-lg tw-font-bold">{{ text }}</div>
			<template #action="{ attrs }">
				<v-btn text v-bind="attrs" @click="snackbar = false"> Fermer</v-btn>
			</template>
		</v-snackbar>

		<div class="tw-justify-center page-loader d-flex align-center elevation-1">
			<v-img
				class="image"
				contain
				height="300"
				lazy-src="@/assets/img/Rapidbook_web.svg"
				src="@/assets/img/Rapidbook_web.svg"
				width="300"
			/>
		</div>

		<v-dialog v-model="dialog" shaped width="500">
			<v-card v-if="info" elevation="24" shaped>
				<v-card-title class="mb-4 text-h5"> {{ $t('global.info') }}</v-card-title>

				<v-card-text class="text-justify">
					<pre class="text-body-1 text-pre-wrap text-break">{{ info }}</pre>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn class="border-shaped yellow--bg" depressed @click="dialog = false"> Ok </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="alertPanierDialog" max-width="600px" transition="dialog-transition">
			<v-card shaped elevation="24">
				<v-card-title>
					<div class="tw-flex tw-justify-between tw-w-full tw-text-xl">
						<v-icon class="tw-hidden warning--text md:tw-block">mdi-alert</v-icon>
						{{ $t('cart_not_empty') }}
						<v-icon class="tw-hidden warning--text md:tw-block">mdi-alert</v-icon>
					</div>
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						class="tw-ml-auto border-shaped yellow--bg"
						depressed
						block
						@click="
							() => {
								alertPanierDialog = false;
								drawerR = true;
							}
						"
					>
						OK
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<cookie-consent link-label="" :message="$t('global.cookie-message')" style="background-color: white">
			<template slot="button">
				<v-btn class="border-shaped yellow--bg" depressed> Accept</v-btn>
			</template>
		</cookie-consent>
	</v-app>
</template>

<script>
	import Panier from '@/components/info/Panier';
	import Tutos from '@/components/info/Tutos';
	import axios from 'axios';
	import { EventBus } from '@/utils';
	import { version } from '@/../package.json';

	export default {
		components: { Panier, Tutos },
		data: () => ({
			dialog: false,
			info: '',
			drawerL: false,
			drawerR: false,
			count: 0,
			snackbar: false,
			snackBars: [],
			color: 'gray',
			text: '',
			alertPanierDialog: false,
			version: version,
		}),
		computed: {
			itemsWithCondition: function () {
				const items = [
					{
						title: this.$t('menu.home'),
						icon: 'mdi-home-outline',
						route: 'Home',
						condition: true,
					},
					{
						title: this.$t('menu.dashboard'),
						icon: 'mdi-view-dashboard-outline',
						route: 'Dashboard',
						condition: this.$store.getters.isAuthenticated,
					},
					{
						title: this.$t('menu.library'),
						icon: 'mdi-bookshelf',
						route: 'Library',
						condition: this.$store.getters.isAuthenticated,
					},
					{
						title: this.$t('menu.orders'),
						icon: 'mdi-format-list-checkbox',
						route: 'Orders',
						condition: this.$store.getters.isAuthenticated && !this.$store.getters.isChild,
					},
					{
						title: this.$t('menu.address'),
						icon: 'mdi-map-marker-outline',
						route: 'Address',
						condition: this.$store.getters.isAuthenticated && !this.$store.getters.isChild,
					},
					{
						title: this.$t('menu.account'),
						icon: 'mdi-account-outline',
						route: 'Account',
						condition: this.$store.getters.isAuthenticated,
					},
					{
						title: this.$t('menu.simulation'),
						icon: 'mdi-poll',
						route: 'Simulation',
						condition: this.$store.getters.isAuthenticated && !this.$store.getters.isChild,
					},
					// {
					// 	title: 'Simulation Test',
					// 	icon: 'mdi-poll',
					// 	route: 'SimulationTest',
					// 	condition: this.$store.getters.isAuthenticated && this.$store.getters.isAdmin,
					// },
					{
						title: this.$t('menu.login'),
						icon: 'mdi-login-variant',
						route: 'Login',
						condition: !this.$store.getters.isAuthenticated,
					},
					{
						title: this.$t('menu.contact'),
						icon: 'mdi-message-outline',
						route: 'Contact',
						condition: true,
					},
					{
						title: this.$t('menu.admin'),
						icon: 'mdi-cog-outline',
						route: 'Admin',
						condition: this.$store.getters.isAuthenticated && this.$store.getters.isAdmin,
					},
					{
						title: this.$t('menu.register'),
						icon: 'mdi-account-plus-outline',
						route: 'Register',
						condition: this.$store.getters.isAuthenticated && this.$store.getters.isAdmin,
					},
					{
						title: this.$t('menu.logout'),
						icon: 'mdi-logout-variant',
						route: 'Logout',
						condition: this.$store.getters.isAuthenticated,
					},
					{
						title: this.$t('menu.need_help'),
						icon: 'mdi-comment-question-outline',
						route: 'Help',
						condition: this.$store.getters.isAuthenticated,
					},
				];
				return items.filter((i) => i.condition);
			},
		},
		watch: {
			drawerR() {
				if (this.drawerR) {
					EventBus.$emit('refreshPanier');
				}
			},
		},
		mounted() {
			this.$root.$i18n.locale = this.$store.getters.getLocale;

			this.$vuetify.lang.current = this.$i18n.locale;
			this.$store.dispatch('changeLocale', this.$i18n.locale);
			this.getPanierCount();

			EventBus.$on('refreshPanier', () => {
				this.getPanierCount();
			});

			EventBus.$on('notif', (text, color) => {
				this.text = text;
				this.color = color;
				this.snackbar = true;
			});

			setTimeout(() => {
				document.querySelector('.page-loader').setAttribute('style', 'transform: translateY(-100vh)');
				document.querySelector('.page-loader').classList.remove('elevation-1');
			}, 2500);

			this.$nextTick(() => {
				this.getInfos();
			});

			window.addEventListener('beforeunload', this.beforeUnload);
		},
		methods: {
			changeLocale(lang) {
				this.$root.$i18n.locale = lang;
				this.$i18n.locale = lang;
				this.$vuetify.lang.current = this.$root.$i18n.locale;
				this.$store.dispatch('changeLocale', this.$root.$i18n.locale);
			},
			getPanierCount() {
				if (this.$store.getters.isAuthenticated && !this.$store.getters.isChild) {
					axios
						.post(
							process.env.VUE_APP_API_URL + '/panier',
							{},
							{
								headers: {
									Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
								},
							}
						)
						.then((response) => {
							this.count = response.data.count;
						})
						.catch(() => {});
				}
			},
			getInfos() {
				axios
					.get(process.env.VUE_APP_API_URL + '/information', {
						headers: this.$store.getters.isAuthenticated
							? {
									Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
							  }
							: null,
					})
					.then((response) => {
						this.info = response.data.information;
						if (this.info.length > 0) {
							this.dialog = true;
						}
					})
					.catch(() => {
						this.info = '';
					});
			},
			beforeUnload(e) {
				if (this.count === 0) {
					return;
				}
				e.preventDefault();
				e.returnValue = '';

				this.alertPanierDialog = true;
				return e.returnValue;
			},
		},
	};
</script>

<style lang="scss">
	@import './node_modules/vue-cookieconsent-component/src/scss/cookie-consent';
	@import './node_modules/vue-cookieconsent-component/src/scss/cookie-consent-bottom';
	@import './node_modules/vue-cookieconsent-component/src/scss/cookie-consent-transition';
	@import 'https://emoji-css.afeld.me/emoji.css';

	:root {
		--color-yellow: #ffd600 !important;
		--overlay-bg: #ffd600 !important;
	}

	.min-h-screen-resized {
		min-height: calc(100vh - 64px);
	}

	.h-screen-resized {
		height: calc(100vh - 64px);
	}

	.overlay-top,
	.overlay-bottom,
	.overlay-left,
	.overlay-right {
		z-index: 9999;
	}

	.yellow--text-override {
		color: var(--color-yellow) !important;
	}

	.yellow--bg {
		background-color: var(--color-yellow) !important;
		color: white;
	}

	.border-shaped {
		border-top-left-radius: 20px !important;
		border-bottom-right-radius: 20px !important;
	}

	.border-shaped-tel .v-text-field--outlined fieldset {
		border-top-left-radius: 20px !important;
		border-bottom-right-radius: 20px !important;
	}

	.yellow--override {
		background-color: var(--color-yellow) !important;
		border-color: var(--color-yellow) !important;
	}

	.page-loader {
		background: white;
		height: 100vh;
		width: 100vw;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9999;
		animation: loader 0.5s 2s;
		transform: translateY(0);

		.image {
			@media (min-width: 976px) {
				width: 50vw;
			}

			animation: svg-loader 2s 0.3s;
			transform: translateY(-100vh);
		}
	}

	@keyframes svg-loader {
		0% {
			transform: translateY(100vh) scale(0.5);
		}

		40% {
			transform: translateY(0) scale(1);
		}

		60% {
			transform: translateY(0) scale(1);
		}

		100% {
			transform: translateY(-100vh) scale(0.5);
		}
	}

	@keyframes loader {
		0% {
			transform: translateY(0);
		}

		100% {
			transform: translateY(-100vh);
		}
	}

	.utils-menu {
		position: fixed;
		bottom: 10px;
		right: 10px;
		z-index: 9999;
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	@keyframes swing {
		0% {
			transform: rotate(0deg);
		}

		10% {
			transform: rotate(10deg);
		}

		30% {
			transform: rotate(0deg);
		}

		40% {
			transform: rotate(-10deg);
		}

		50% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(0deg);
		}
	}

	.swing {
		animation: swing 1s linear infinite both;
	}

	.gap-2 {
		gap: 0.5rem;
	}

	.gap-3 {
		gap: 1rem;
	}

	.gap-4 {
		gap: 1.5rem;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.3s ease, filter 0.3s ease-in-out;
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
		filter: blur(10px);
	}
</style>

<i18n lang="json">
{
	"fr": {
		"cart_not_empty": "Attention, le panier n'est pas vide !"
	},
	"en": {
		"cart_not_empty": "Warning, the cart is not empty !"
	}
}
</i18n>
