<template>
	<section class="orders">
		<page-title :title="$t('title')" :breadcrumbs="items"></page-title>
		<v-container>
			<v-row>
				<v-col>
					<v-card-title>
						<div></div>
						<v-spacer></v-spacer>
						<v-text-field
							v-model="search"
							class="border-shaped"
							append-icon="mdi-magnify"
							:label="$t('form.search')"
							single-line
							hide-details
							outlined
						></v-text-field>
					</v-card-title>
					<v-data-table
						:headers="headers"
						:items="orders"
						:options.sync="options"
						:items-per-page="itemsPerPage"
						:server-items-length="totalOrders"
						:footer-props="footerProps"
						class="elevation-1 border-shaped"
						:loading="loading"
					>
						<template #[`item.action`]="{ item }">
							<order-detail :order="item"></order-detail>
							<v-btn
								v-if="[1, 2].includes(item.f1_id)"
								color="red"
								class="border-shaped my-1 my-xl-0 ml-xl-1"
								depressed
								@click="deleteOrder(item.id)"
							>
								<v-icon>mdi-delete</v-icon>
							</v-btn>
						</template>
						<template #[`item.f1.name`]="{ item }">{{ $t('order_status.' + item.f1.id) }}</template>
						<template #[`item.prix_fabrication`]="{ item }">{{ parseFloat(item.prix_fabrication).toFixed(2) }} €</template>
						<template #[`item.prix_livraison`]="{ item }">{{ parseFloat(item.prix_livraison).toFixed(2) }} €</template>
						<template #[`item.prix_emballage`]="{ item }">{{ parseFloat(item.prix_emballage).toFixed(2) }} €</template>
						<template #[`item.prix_ttc`]="{ item }">{{ parseFloat(item.prix_ttc).toFixed(2) }} €</template>
						<template #[`item.prix_ht`]="{ item }">{{ parseFloat(item.prix_ht).toFixed(2) }} €</template>
						<template #[`item.created_at`]="{ item }">
							<v-chip small outlined>
								{{ new Date(new Date(item.created_at).toLocaleString('en-US') + ' UTC').toLocaleString() }}
							</v-chip>
						</template>
						<template #[`item.lignes_commande`]="{ item }">
							<div>
								<span>{{ item.lignes_commande.length }}</span>
							</div>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-container>
	</section>
</template>

<script lang="js">
	import PageTitle from '@/components/info/PageTitle';
	import axios from 'axios';
	import OrderDetail from '@/components/order/OrderDetail';
	import { EventBus } from '@/utils';

	export default {
	    name: 'Orders',
	    metaInfo: {
	        title: 'Rapidbook - Orders',
	        metas: [
	            {
	                name: 'com.silverpop.brandeddomains',
	                content: 'www.pages06.net,corlet.fr,rapidbook.fr,www.corlet.fr,www.rapidbook.fr',
	            },
	        ],
	        script: [
	            {
	                src: 'https://www.sc.pages06.net/lp/static/js/iMAWebCookie.js?40625c3e-18bd96a8009-3c9d767ebba0be65b5fa68bf26a6ee93&h=www.pages06.net',
	            },
	        ],
	    },
	    components: { OrderDetail, PageTitle },
	    props: [],
	    data() {
	        return {
	            search: '',
	            intervalSearch: null,
	            loading: true,
	            options: {
	                sortBy: ['created_at'],
	                sortDesc: [true],
	                itemsPerPage: 15,
	                page: 1,
	            },
	            itemsPerPage: 15,
	            orders: [],
	            totalOrders: 0,
	        };
	    },
	    computed: {
	        items: function () {
	            return [
	                {
	                    text: 'Rapidbook',
	                    disabled: false,
	                    to: '/',
	                },
	                {
	                    text: 'Dashboard',
	                    disabled: false,
	                    to: 'dashboard',
	                },
	                {
	                    text: this.$t('menu.orders'),
	                    disabled: true,
	                    to: '',
	                },
	            ];
	        },
	        headers: function () {
	            return [
	                { text: this.$t('order.id'), align: 'start', value: 'uid' },
	                { text: this.$t('order.status'), value: 'f1.name' },
	                { text: this.$t('order.price.production'), value: 'prix_fabrication' },
	                { text: this.$t('order.price.packaging'), value: 'prix_emballage' },
	                { text: this.$t('order.price.shipping'), value: 'prix_livraison' },
	                { text: this.$t('order.price.ht'), value: 'prix_ht' },
	                { text: this.$t('order.price.ttc'), value: 'prix_ttc' },
	                { text: this.$t('global.created_at'), value: 'created_at' },
	                { text: this.$t('order.line.lines'), value: 'lignes_commande' },
	                { text: this.$t('global.action'), value: 'action' },
	            ];
	        },
	        footerProps: function () {
	            return {
	                'items-per-page-options': [5, 10, 15, 30, 50, 100],
	                itemsPerPageText: this.$t('global.items_per_page'),
	            };
	        },
	    },
	    watch: {
	        options: {
	            handler() {
	                this.getOrders();
	            },
	            deep: true,
	        },
	        search() {
	            clearTimeout(this.intervalSearch);
	            this.intervalSearch = setTimeout(() => {
	                this.options.search = this.search;
	                this.getOrders();
	            }, 750);
	        },
	    },
	    mounted() {
	        EventBus.$on('refreshOrders', () => {
	            this.getOrders();
	        });
	    },
	    methods: {
	        getOrders() {
	            this.loading = true;
	            axios
	                .get(process.env.VUE_APP_API_URL + '/order', {
	                    params: {
	                        sortBy: this.options.sortBy[0],
	                        sortDesc: this.options.sortDesc[0],
	                        itemsPerPage: this.options.itemsPerPage,
	                        search: this.search,
	                        page: this.options.page,
	                    },
	                    headers: {
	                        Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
	                    },
	                })
	                .then((response) => {
	                    this.orders = response.data.orders;
	                    this.totalOrders = response.data.total;
	                    this.loading = false;
	                })
	                .catch(() => {
	                    this.loading = false;
	                });
	        },
	        deleteOrder(id) {
	            if (confirm('Êtes-vous sûr de vouloir supprimer cette commande ?')) {
	                axios
	                    .delete(process.env.VUE_APP_API_URL + '/order/' + id, {
	                        headers: {
	                            Authorization: `Bearer ${this.$store.getters.getJwtToken}`,
	                        },
	                    })
	                    .then(() => {
	                        EventBus.$emit('notif', 'Commande supprimée', 'success');
	                        EventBus.$emit('refreshPanier');
	                        EventBus.$emit('refreshOrders');
	                    })
	                    .catch(() => {
	                        EventBus.$emit('refreshPanier');
	                        EventBus.$emit('refreshOrders');
	                        EventBus.$emit('notif', "Une erreur s'est produite", 'error');
	                    });
	            }
	        },
	    },
	};
</script>

<style scoped lang="scss">
	.orders {
		min-height: calc(100vh - 64px);
	}
</style>

<i18n lang="json">
{
	"fr": {
		"title": "Mes Commandes"
	},
	"en": {
		"title": "My Orders"
	}
}
</i18n>
