import { render, staticRenderFns } from "./AddressDetail.vue?vue&type=template&id=4e4f9510"
import script from "./AddressDetail.vue?vue&type=script&lang=js"
export * from "./AddressDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./AddressDetail.vue?vue&type=custom&index=0&blockType=i18n&lang=json"
if (typeof block0 === 'function') block0(component)

export default component.exports