module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "will_be_recalculated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'il s'agit d'une ancienne commande les prix seront recalculés"])},
        "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facture Proforma"])}
      },
      "en": {
        "will_be_recalculated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If it is an old order the prices will be recalculated"])},
        "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proforma Invoice"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"will_be_recalculated":"S\u0027il s\u0027agit d\u0027une ancienne commande les prix seront recalculés","invoice":"Facture Proforma"},"en":{"will_be_recalculated":"If it is an old order the prices will be recalculated","invoice":"Proforma Invoice"}}')
  delete Component.options._Ctor
  
}
