module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "need_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'aide ?"])},
        "tuto_add_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une adresse ?"])},
        "tuto_add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un titre ?"])},
        "tuto_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer commande ?"])},
        "tuto_simulate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faire un devis ?"])}
      },
      "en": {
        "need_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help ?"])},
        "tuto_add_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add address ?"])},
        "tuto_add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add title ?"])},
        "tuto_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order ?"])},
        "tuto_simulate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulate ?"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"need_help":"Besoin d\u0027aide ?","tuto_add_address":"Ajouter une adresse ?","tuto_add_title":"Ajouter un titre ?","tuto_order":"Passer commande ?","tuto_simulate":"Faire un devis ?"},"en":{"need_help":"Need help ?","tuto_add_address":"Add address ?","tuto_add_title":"Add title ?","tuto_order":"Order ?","tuto_simulate":"Simulate ?"}}')
  delete Component.options._Ctor
  
}
