module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "items_per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produits par page"])},
        "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune adresse enregistrée"])}
      },
      "en": {
        "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "items_per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products per page"])},
        "no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No address registered"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"sort":"Trier par","date":"Date","items_per_page":"Produits par page","no_data":"Aucune adresse enregistrée"},"en":{"sort":"Sort by","date":"Date","items_per_page":"Products per page","no_data":"No address registered"}}')
  delete Component.options._Ctor
  
}
